@import "./../../../../variables";

.event-header-container {
  display: flex;
  flex-direction: column;
  background-image: url("./../../../../assets/images/event_background.png");
  width: 1440px;
  height: 540px;
  background-size: cover;
  font-family: $cool-font-family;
  color: $gertner-dark-grey;

  @media only screen and (max-width: $breakpoint-desktop) {
    width: 700px;
    background-size: cover;
    background-repeat: no-repeat;
    height: 540px;
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    background-image: url("./../../../../assets/images/event_background-mobile.png");
    width: 375px;
    height: 539px;
  }

  &__title {
    max-width: 358px;
    margin-left: 254px;
    margin-top: 181px;
    font-style: italic;
    font-weight: 900;
    font-size: 48px;
    line-height: 48px;
    @media only screen and (max-width: $breakpoint-desktop) {
      margin-left: 150px;
    }
    @media only screen and (max-width: $breakpoint-tablet) {
      max-width: 301px;
      margin-top: 50px;
      margin-left: 37px;
      font-size: 36px;
      line-height: 36px;
    }
  }

  &__sub-title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    max-width: 358px;
    margin-left: 254px;
    margin-top: 21px;
    @media only screen and (max-width: $breakpoint-desktop) {
      margin-left: 150px;
    }
    @media only screen and (max-width: $breakpoint-tablet) {
      margin-top: 10px;
      margin-left: 37px;
      max-width: 143px;
    }
  }
}
