.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.react-icons {
    vertical-align: middle;
}

.toast-wrapper {
  height: 0;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
