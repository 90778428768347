@import "./../../variables.scss";

.admin-navbar {
  display: flex;
  flex-direction: column;
  position: sticky;
  justify-content: center;
  flex-wrap: wrap;
  top: 0;
  z-index: 2;
  @media only screen and (max-width: $breakpoint-tablet) {
    position: unset;
  }

  &__link {
    color: #282c34 !important;
    @media only screen and (max-width: $breakpoint-tablet) {
      min-height: 30px !important;
      height: 10px !important;
      line-height: 10px;
      flex-wrap: wrap;
    }
  }

  &__link:hover {
    -webkit-box-shadow: inset 10px 10px 269px -130px rgba(67,173,173,1);
    -moz-box-shadow: inset 10px 10px 269px -130px rgba(67,173,173,1);
    box-shadow: inset 10px 10px 269px -130px rgba(67,173,173,1);
  }

  &__navigation{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 15px;
    li {
      margin-left: 20px;
      list-style: none;
    }
    @media only screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
      li {
        height: 30px;
        padding-top: 10px;
      }
      a {
        height: 30px;
      }
    }
  }

  &__logout-btn {
    margin-left: 20px;
    @media only screen and (max-width: $breakpoint-tablet) {
      margin-top: 25px;
    }
  }

  &__list {
    background-color: #C4C4C4;
    box-shadow: 0 8px 6px -6px black;
    color: #343434;
  }

  &__badge {
    animation: pulse 0.8s ease-in infinite;
  }
}
@keyframes pulse{
  15%  {transform: scale(1.2);}
  5%  {transform: scale(1.2);}
}
