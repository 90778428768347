@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap');

$breakpoint-desktop-large: 1200px;
$breakpoint-desktop: 1024px;
$breakpoint-mobile: 360px;
$breakpoint-tablet: 560px;

$cool-font-family: 'Montserrat';
$montserrat-black: 'Montserrat Black';
$montserrat-black-italic: 'Montserrat-Black-Italic';


//colors
$gertner-dark-blue: #13444A;
$gertner-light-grey: #F7F7F7;
$gertner-greyish: #47474782;
$gertner-dark-brown: #171413;
$gertner-gold: #ECDFAB;
$gertner-grey: #474747;
$gertner-dark-grey: #343434;
$gertner-error-red: #d32533;
