.product-medal-modal {
  display: flex;

  &__row {
    display: flex;
    border-top: 1px solid black;
    padding: 15px;
    &--label {
      display: flex;
      font-weight: bold;
      width: 90%;
    }

  }
}
