@import "/../../../../../variables";

.product-full-container {
  font-family: $cool-font-family;
  display: flex;
  flex-direction: row;
  color: $gertner-dark-blue;
  margin-top: 52px;
  justify-content: center;
  @media only screen and (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }

  &__image {
    @media only screen and (max-width: $breakpoint-tablet) {
      display: flex;
      justify-content: center;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    @media only screen and (max-width: $breakpoint-tablet) {
      align-items: center;
    }
  }

  &__color {
    font-style: italic;
    font-weight: 900;
    font-size: 18px;
    line-height: 36px;
    text-decoration-line: underline;
    color: #C4C4C4;
  }

  &__name {
    font-style: italic;
    font-weight: 900;
    font-size: 48px;
    line-height: 56px;
    margin-top: 11px;
    @media only screen and (max-width: $breakpoint-tablet) {
      text-align: center;
    }
  }

  &__vintage {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    margin-top: 7px;
  }

  &__category {

    margin-top: 60px;

    &--label {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
    }

    &--value {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
    }
  }

  &__text {
    margin-top: 37px;
    max-width: 546px;
  }

  &__iterator {
    margin-top: 37px;
  }

  &__info {
    display: flex;
    margin-top: 90px;
  }

  &__params{
    &--label {
      margin-top: 20px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
    }
    &--value {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
    }
  }

  &__medals {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #C4C4C4;
    margin-left: 60px;
    margin-top: 20px;
    ul {
      list-style-image: url("./../../../../../assets/images/odrazka-x-zluta.png");
    }
  }
}
