@import "./../../../variables";

.order-statistics-container {
  font-family: $cool-font-family;
  width: 300px;
  background: rgb(172,172,172);
  background: linear-gradient(308deg, rgba(172,172,172,1) 17%, rgba(161,161,161,1) 33%, rgba(255,254,254,1) 51%, rgba(177,177,177,1) 84%, rgba(140,142,143,1) 95%);
  border-radius: 8px;
  margin: 1em;
  opacity: 0.9;
  box-shadow: 0 2px 16px 1px rgba(33, 33, 33, 0.5);
  padding: 2em;
  font-size: 15px;
  font-weight: bold;
}
