@import "/../../../variables";


.eshop-toast-container {
  top: 0;
  position: -webkit-sticky; /* Safari */
  color: $gertner-dark-blue;
  font-family: $cool-font-family;
  z-index: 1000;

  &__toast {
    border: 1px solid #C4C4C4;
    max-width: 1200px;
  }
  &__title {
    font-weight: 400;
    font-style: italic;
  }

  &__product {
    text-decoration: underline;
  }
}
