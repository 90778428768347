@import "./../../../../variables";

.event-dashboard-content {
  max-width: 1440px;
  height: auto;
  padding-bottom: 91px;
  background-color: $gertner-grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: $breakpoint-tablet) {
    width: 375px;
  }

  &__container {
    display: flex;
    max-width: 900px;
    flex-flow: row wrap;
    @media only screen and (max-width: $breakpoint-tablet) {
      width: 375px;
      flex-direction: column;
      height: auto;
    }
  }

  &__item {
    display: flex;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 50px;
    @media only screen and (max-width: $breakpoint-tablet) {
      padding-bottom: 60px;
    }
  }

  &__next-events {
    width: 135px;
    font-style: italic;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: $gertner-gold;
    margin-left: 890px;
    margin-top: 70px;
    @media only screen and (max-width: $breakpoint-tablet) {
      width: 120px;
      margin-left: 60px;
      margin-top: 30px;
    }
  }

  &__next-events:hover {
    text-decoration-line: underline;
    cursor: pointer;
  }
}
