@import '/../../../../variables';

.order-complete-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: $cool-font-family;

  &__header {
    display: flex;
    justify-content: center;
    margin-top: 90px;
  }

  &__header-image {
    display: flex;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("./../../../../assets/images/complete-image.png");
    height: 490px;
    width: 1233px;

    @media only screen and (max-width: $breakpoint-tablet) {
      justify-content: center;
      align-items: center;
      height: 300px;
    }
  }

  &__title {
    color: #FFFFFF;
    font-style: italic;
    font-weight: 900;
    font-size: 36px;
    line-height: 36px;
    text-align: right;
    max-width: 300px;
    margin-top: 20%;
    margin-left: 50%;
    border-top: 3px solid #FFFFFF;
    padding-top: 20px;

    @media only screen and (max-width: $breakpoint-tablet) {
      margin-left: 0;
      text-align: left;
      color: $gertner-dark-blue;
      margin-top: 55%;
      border-top: 2px solid $gertner-dark-blue;
    }

  }

  &__content {
    display: flex;
    justify-content: center;
  }

  &__text {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 28px;
    color: $gertner-dark-blue;
    margin-top: 50px;
    border-bottom: 2px solid $gertner-dark-blue;
    padding: 10px;

  }

  &__row {
    display: flex;
    flex-wrap: wrap;

    &--header {
      border-bottom: 2px solid $gertner-dark-blue;
      margin-bottom: 30px;
    }

    &--sum {
      border-top: 2px solid $gertner-dark-blue;
    }
  }

  &__col {
    display: flex;
    padding: 10px;
    &--bold {
      font-weight: 600;
      width: 300px;
    }

    &--cart {
      width: 150px;
    }
    &--no-vat {
      width: 300px;
      font-style: normal;
      font-weight: normal;
      color: $gertner-greyish;
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: $gertner-dark-blue;
    margin-top: 50px;
    @media only screen and (max-width: $breakpoint-tablet) {
      padding-left: 20px;
    }
  }

  &__cart-title {
    font-weight: 900;
    margin-top: 30px;
    border-bottom: 2px solid $gertner-dark-blue;
    margin-bottom: 20px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  &__buttons-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__link-parent {
    margin-top: 30px;
  }

  &__link {
    font-style: italic;
    font-weight: 900;
    font-size: 21px;
    line-height: 36px;
    text-decoration-line: underline;
    color: $gertner-dark-blue;
    cursor: pointer;
  }

  &__eshop-menu {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }
}
