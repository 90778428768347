@import "/../../../../variables";

.delivery-container {

  display: flex;
  flex-direction: column;
  font-family: $cool-font-family;
  width: 100%;
  max-width: 1000px;

  @media only screen and (max-width: $breakpoint-tablet){
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-style: italic;
    font-weight: 900;
    font-size: 36px;
    line-height: 36px;
    padding-left: 20px;
    color: $gertner-dark-blue;
  }

  &__checkboxes {
    display: flex;
    margin-top: 50px;
    @media only screen and (max-width: $breakpoint-tablet){
      flex-direction: column;
    }
  }
}


.top-trans-warning {
  margin-top: 50px;
  max-width: 450px;
  padding: 20px;
  color: $gertner-error-red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__description {
    margin-top: 20px;
  }
  &__account {
    font-weight: 900;
    font-style: italic;
  }
  span {
    font-weight: 900;
    font-style: italic;
  }
}
