@import "/../../../../variables";

.product-detail-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;

  &__back-to-list {
    font-family: $cool-font-family;
    font-weight: 900;
    max-width:1000px;
    width: 100%;
    margin-top: 50px;
    display: flex;
    color: $gertner-dark-blue;
    font-size: 20px;
    cursor: pointer;
    font-style: italic;
    text-decoration: underline;
  }

  &__cart {
    margin-top: 285px;
  }

  &__similar-products {
    font-family: $cool-font-family;
    width: 300px;
    font-style: italic;
    font-weight: 900;
    font-size: 36px;
    line-height: 36px;
    color: #C4C4C4;
    border-top: 2px solid #C4C4C4;
    padding-top: 20px;
    margin-left: 20px;
    margin-top: 40px;
  }

  &__top-three {
  }
}


