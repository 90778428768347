@import "/../../variables";

.gertner-checkbox-container {
  display: flex;
  width: 300px;

  .container-checkbox {
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: $cool-font-family;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }


  .checkmark {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FFFFFF;
    border: 2px solid $gertner-dark-blue;

    &--small {
      width: 30px;
      height: 30px;
    }
  }

  .container-checkbox .checkmark:after {
    width: 36px;
    height: 36px;
    left: 20%;
    right: 20%;
    top: 20%;
    bottom: 20%;
    border-width: 0 3px 3px 0;
    background-image: url("./../../assets/images/icons/cross-check.svg");
  }

  .container-checkbox .checkmark--small:after {
    width: 15px;
    height: 15px;
    left: 23%;
    top: 23%;
    border-width: 0 3px 3px 0;
    background-image: url("./../../assets/images/icons/cross-check.svg");
    background-size: contain;
  }


  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container-checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  &__texts {
    display: flex;
    width: 300px;
    flex-direction: column;
    margin-left: 20%;
    padding-left: 20px;
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 28px;
    color: #13444A;
  }

  &__description {
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 28px;
  }

  &__price {
    font-size: 21px;
    line-height: 28px;
    color: #C4C4C4;
  }
}
