@import "./../../variables";

.order-popup-container {
  @media only screen and (max-width: $breakpoint-tablet) {
    font-size: 14px;
  }

  &__title {
    @media only screen and (max-width: $breakpoint-tablet) {
      font-size: 16px !important;
    }
  }
}
