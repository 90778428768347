.orders-container {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
  &__table-block {
    max-width: 1280px;
  }
}

.orders-container-mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}
