@import "/../../../../variables";

.cart-banner-container {
  display: flex;
  width: 100%;
  max-width: 1440px;
  justify-content: space-around;
  flex-wrap: wrap;
  color: #C4C4C4;
  font-family: $cool-font-family;
  padding-top: 50px;
  &__contacts {
    display: flex;
    flex-direction: column;
    a {
      color: #C4C4C4;
      font-family: $cool-font-family;
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 28px;
      text-decoration-line: none;
    }
    a:hover {
      text-decoration-line: underline;
    }
  }

  &__cart-info {
    display: flex;
    font-style: italic;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    text-align: right;
    text-decoration-line: underline;
    cursor: pointer;
  }

  &__icon {
    margin-left: 26px;
  }

  &__info{
    &--rest-bottle {
      font-family: $cool-font-family;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      text-decoration-line: underline;
    }
  }

}
