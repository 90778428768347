@import "/../../variables";


.gertner-input-container {
  display: flex;
  flex-direction: column;
  font-family: $cool-font-family;
  color: $gertner-dark-blue;

  @media only screen and (max-width: $breakpoint-tablet) {
    width: 250px;
  }

  &__label {
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 27px;
  }

  &__input {
    border: 2px solid $gertner-dark-blue;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 23px;
    font-family: $cool-font-family;
    color: $gertner-dark-blue;
    font-style: italic;
    font-weight: 900;
    font-size: 21px;
    line-height: 27px;
    display: flex;
    align-items: center;

    @media only screen and (max-width: $breakpoint-tablet) {
      font-size: 18px;
    }

    &--nonvalid {
      border: 2px solid $gertner-error-red;
      color: $gertner-error-red;
    }
  }
}
