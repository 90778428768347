@import "./../../../variables";

.header-container {
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 200px;

  @media only screen and (max-width: $breakpoint-tablet) {
    margin-top: 95px;
  }

  &__description {
    cursor: pointer;
    justify-content: center;
    margin-top: 60px;
  }
}
