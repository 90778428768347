@import "/../../../variables";

.order-confirmation-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__link {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  &__cart {
    margin-top: 160px;
  }

  &__delivery, &__payment-method {
    display: flex;
    width: 100%;
    max-width: 1000px;
    margin-top: 120px;

    @media only screen and (max-width: $breakpoint-desktop) {
      margin-left: 10%;
    }
  }

  &__form {
    display: flex;
    width: 100%;
    max-width: 1000px;
    margin-top: 120px;
  }

  &__eshop-menu {
    display: flex;
    margin-top: 200px;
    justify-content: center;
  }
}
