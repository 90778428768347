@import "./../../../variables";

.intro-container {
  max-width: 1440px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 48px;
  text-align: center;

  &__text-title {
    max-width: 336px;
    display: flex;
    font-family: $cool-font-family;
    font-weight: 900;
    font-style: italic;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    color: $gertner-dark-blue;
  }

  &__text-description {
    font-family: $cool-font-family;
    max-width: 460px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin-top: 36px;
    color: $gertner-dark-blue;
  }

  &__btns {
    display: flex;
    text-align: center;
    align-items: center;
    margin-top: 50px;

    @media only screen and (max-width: $breakpoint-tablet) {
     flex-direction: column;
      justify-content: center;
      align-items: center;
    }

  }

  &__button {
    margin-left: 20px;
  }

  &__small_nav {
    display: flex;
    flex-direction: column;
    margin-top: 33px;

    span {
      cursor: pointer;
      font-style: italic;
      font-weight: 900;
      font-size: 21px;
      line-height: 36px;
      color: $gertner-dark-blue;
    }

    span:hover {
      text-decoration: underline;
    };
  }

  &__image {
    width: 940px;
    height: 459px;
    background:  url('./../../../assets/images/gertner-ruka.png');
    @media only screen and (max-width: $breakpoint-desktop){
      max-width: 650px;
      background-size: contain;
      background-repeat: no-repeat;
    }
    @media only screen and (max-width: $breakpoint-tablet) {
      background-image: url('./../../../assets/images/gertner-ruka-mobile.png');
      width: 361px;
      height: 176px;
    }
  }
}

.photo-banner-component {
  margin-top: 141px;
  margin-bottom: 141px;
  @media only screen and (max-width: $breakpoint-tablet) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
