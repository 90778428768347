@import "./../../../../variables";

.motorbike-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .slide {
    background-color: #FFFFFF;
  }
}

.slideshow-text {
  background-color: #ffffff;
  text-align: left;
  font-family: $cool-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: $gertner-dark-blue;
  margin-top: 30px;
  padding-left: 90px;
  padding-right: 90px;
  padding-top: 10px;
  height: 250px;

  @media only screen and (max-width: $breakpoint-tablet) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.slideshow-image {
  padding: 20px;
}

.slideshow-container {
  background-color: $gertner-light-grey;
}

.slideshow-splitter {
  width: 103px;
  height: 15px;
}

.motorbike-title {
  width: 338px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 89px;
  margin-bottom: 35px;
  font-family: $cool-font-family;
  font-style: italic;
  font-weight: 900;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: $gertner-dark-blue;


}
