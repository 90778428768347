@import "./../../variables";
@import url('https://fonts.googleapis.com/css?family=Montserrat|Montserrat+Alternates&display=swap');

.product-admin-container {
  font-family: $cool-font-family;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

 &__table {
   max-width: 1024px;
   margin-top: 50px;
   -webkit-box-shadow: 0 0 60px 12px rgba(0,0,0,0.75);
   -moz-box-shadow: 0 0 60px 12px rgba(0,0,0,0.75);
   box-shadow: 0 0 60px 12px rgba(0,0,0,0.75);
   td {
     padding-left: 40px;
   }
   th{
     font-weight: bold;
     font-family: $cool-font-family;
     padding-left: 40px;
     padding-top: 50px;
   }

   @media only screen and (max-width: $breakpoint-tablet) {
     font-size: 13px;
     th {
       padding-left: 10px;
       padding-top: 10px;
       font-size: 12px;
     }
     td {
       padding-left: 10px;
     }
   }
 }

  &__edit-icon {
    cursor: pointer;
  }

  &__mobile-td {
    display: none;
  }

  &__search-bar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 100%;
    border: 0;
    background: #fff;
    display: block;
    width: 500px;
    padding: 10px 32px 10px 70px;
    font-size: 24px;
    color: #666;
    border-radius: 3px;
    margin-top: 20px;
    -webkit-box-shadow: 0px 3px 20px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 20px 1px rgba(0,0,0,0.75);
    box-shadow: 0px 3px 20px 1px rgba(8, 4, 4, 0.75);
    @media only screen and (max-width: $breakpoint-tablet) {
      font-size: 18px;
      width: 260px;
      margin-right: 40px;
    }
  }

  &__search-bar:focus {
    -webkit-box-shadow: 0px 3px 20px 1px rgba(0, 1, 114, 0.75);
    -moz-box-shadow: 0px 3px 20px 1px rgba(0, 1, 114, 0.87);
    box-shadow: 0px 3px 20px 1px rgba(11, 26, 39, 0.9);
  }

  &__new-btn {
    color: rgba(40, 44, 52, 0.77);
    font-weight: bolder;
    cursor: pointer;
  }

}

