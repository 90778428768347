@import '/../../../../variables';

.product-list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;
  max-width: 1440px;

  @media only screen and (max-width: $breakpoint-tablet) {
    max-width: 375px;
  }

  &__list {
    max-width: 970px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 66px;

    @media only screen and (max-width: $breakpoint-tablet)  {
      margin-top: 20px;
    }
  }

  &__products {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 90px;
  }

  &__product {
    @media only screen and (max-width: $breakpoint-tablet)  {
      flex-basis: 50%;
    }
  }

  &__filter {
    display: flex;
    flex-direction: row;
    margin-top: 60px;

    @media only screen and (max-width: $breakpoint-tablet)  {
      flex-direction: column;
      margin-top: 10px;
    }
  }
}

.filter-label {
  display: flex;
  flex-direction: row;
  font-family: $cool-font-family;
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 36px;
  color: $gertner-dark-blue;
  margin-left: 60px;

  @media only screen and (max-width: $breakpoint-tablet)  {
    margin-top: 20px;
  }

  &__cross {
    cursor: pointer;
  }

  &__text {
    padding-left: 21px;
  }
}
