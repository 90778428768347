@import "/../../../variables";

.product-iterator-container {

  &__btn {
    width: 27px;
    height: 27px;
    text-decoration: none;
    background-color: #FFFFFF;
    border: 2px solid #C4C4C4;
    color: #C4C4C4;
    font-size: 18px;
    text-align: center;
  }

  &__text {
    color: #C4C4C4;
    font-family: $cool-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    margin-left: 23px;
    margin-right: 23px;
  }
}
