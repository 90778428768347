@import "./../../variables";

.gertner-button {
  border: 3px solid $gertner-dark-blue;
  box-sizing: border-box;
  background-color: #ffffff;
  color: $gertner-dark-blue;
  width: 300px;
  padding: 10px 22px;
  text-align: center;
  font-family: $cool-font-family;
  font-style: italic;
  font-weight: 900;
  font-size: 21px;
  line-height: 26px;
  text-decoration-line: underline;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
}

.gertner-button:hover {
  background-color: $gertner-dark-blue;
  color: #ffffff;
}

.gertner-button-mobile {
  border: 3px solid $gertner-dark-blue;
  box-sizing: border-box;
  background-color: #ffffff;
  color: $gertner-dark-blue;
  max-width: 143px;
  padding: 10px 32px;
  text-align: center;
  font-family: $cool-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-decoration-line: underline;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
}

.gertner-button-mobile:hover {
  background-color: $gertner-dark-blue;
  color: #ffffff;
}
