@import "./../../variables";

.error-component {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: $cool-font-family;
  &__header {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    @media only screen and (max-width: $breakpoint-desktop) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__header-image {
    display: flex;
    background-image: url("./../../assets/images/broken_bottle.png");
    height: 325px;
    width: 311px;
    @media only screen and (max-width: $breakpoint-tablet) {
      height: 162px;
      width: 156px;
      background-size: contain;
      justify-content: center;
      align-items: center;
    }
  }


  &__title {
    max-width: 500px;
    color: $gertner-dark-blue;
    font-style: italic;
    font-weight: 900;
    font-size: 36px;
    line-height: 36px;
    margin-top: 150px;
    text-align: right;
    display: flex;

    @media only screen and (max-width: $breakpoint-tablet) {
      font-size: 18px;
      margin-left: 0;
      text-align: left;
      color: $gertner-dark-blue;
      border-top: 2px solid $gertner-dark-blue;
      padding: 20px;
      margin-top: 20px;
    }

  }

  &__content {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 200px;
  }

  &__link {
    font-style: italic;
    font-weight: 900;
    font-size: 21px;
    line-height: 36px;
    text-decoration-line: underline;
    color: $gertner-dark-blue;
    cursor: pointer;
  }
}
