@import './../../variables';

.toast-container {
  z-index: 5;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &__hidden {
    display: none;
  }
}

.admin-dashboard {
  font-family: $cool-font-family;
}
