@import "./../../../../variables";

.photo-banner-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media only screen and (max-width: $breakpoint-desktop) {
    width: 600px;
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    width: 375px;
  }
  &__image {
    cursor: pointer;
    width: 25%;
    @media only screen and (max-width: $breakpoint-desktop) {
      width: 300px;
      height: 300px;
    }
    @media only screen and (max-width: $breakpoint-tablet) {
      width: 50%;
      height: 185px;
    }
  }
}
