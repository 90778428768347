@import "/../../../../variables";


.order-form-container {
  display: flex;
  flex-direction: column;
  font-family: $cool-font-family;
  max-width: 1440px;
  width: 100%;

  @media only screen and (max-width: $breakpoint-tablet) {
    justify-content: center;
    align-items: center;
  }

  &__title {
    max-width: 320px;
    color: $gertner-dark-blue;
    font-style: italic;
    font-weight: 900;
    font-size: 36px;
    line-height: 36px;
    @media only screen and (max-width: $breakpoint-desktop) {
      margin-left: 10%;
    }
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 43px;

    @media only screen and (max-width: $breakpoint-desktop) {
      margin-left: 10%;
    }
    @media only screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
    }

    &--item {
      margin-right: 40px;
      margin-top: 40px;
    }
  }

  &__error {
    width: 320px;
    color: $gertner-error-red;
    padding: 20px;
    font-style: italic;
    font-size: 18px;
  }

  &__company-shopping {
    display: flex;
    margin-top: 60px;
  }
}

.order-summary-container {
  background-color: $gertner-dark-blue;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  font-weight: normal;
  font-size: 21px;
  line-height: 36px;
  text-align: right;
  color: #FFFFFF;
  margin-top: 128px;
  padding-bottom: 61px;

  @media only screen and (max-width: $breakpoint-tablet) {
    justify-content: center;
  }

  &__info {
    margin-right: 60px;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__summary {
    font-style: italic;
    font-weight: 900;
    font-size: 36px;
    line-height: 36px;
    color: #ECDFAB;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    max-width: 400px;
    &--title {
      border-top: 3px solid #ECDFAB;
      padding-top: 20px;
    }
  }

  &__no-vat {
    font-size: 18px;
    font-style: normal;
  }

  &__finish-block {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    @media only screen and (max-width: $breakpoint-desktop) {
      align-items: center;
    }

    &--text {
      display: flex;
      flex-direction: column;
      max-width: 450px;
      font-weight: normal;
      font-size: 21px;
      line-height: 36px;
      text-align: right;
      color: $gertner-dark-blue;
      margin-top: 60px;
      padding-right: 20px;
      @media only screen and (max-width: $breakpoint-desktop) {
        margin-right: 0;
      }

      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &--buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 95px;
      @media only screen and (max-width: $breakpoint-tablet) {
        flex-direction: column;
      }
    }

    &--button {
      margin-left: 300px;
      @media only screen and (max-width: $breakpoint-desktop) {
        margin-left: 0;
      }
    }
  }

  &__more-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
    color: $gertner-dark-blue;
    &--textarea {
      border: 2px solid $gertner-dark-blue;
      color: $gertner-dark-blue;
      font-family: $cool-font-family;
      font-size: 14px;
      line-height: 25px;
      max-width: 50%;

      @media only screen and (max-width: $breakpoint-tablet) {
        max-width: 100%;
      }
    }

    &--textarea-invalid {
      border: 2px solid $gertner-error-red;
    }

    span {
      text-decoration: none;
      font-weight: normal;
      margin-left: 20px;
    }

    &--title label {
      display: flex;
      text-decoration: none;
      max-width: 320px;
      color: $gertner-dark-blue;
      font-style: italic;
      font-weight: 900;
      font-size: 26px;
      line-height: 36px;
    }
  }
}
