@import "./../../../../variables";

.payment-method-container {
  display: flex;
  flex-direction: column;
  font-family: $cool-font-family;
  width: 100%;
  max-width: 1000px;

  @media only screen and (max-width: $breakpoint-tablet){
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-style: italic;
    font-weight: 900;
    font-size: 36px;
    line-height: 36px;
    padding-left: 20px;
    color: $gertner-dark-blue;
  }

  &__selection {
    display: flex;
    margin-top: 50px;
    @media only screen and (max-width: $breakpoint-tablet){
      flex-direction: column;
    }
  }
}
