@import "./../../variables";

.medal-admin-container {
  font-family: $cool-font-family;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  &__table {
    max-width: 1024px;
    margin-top: 50px;
    -webkit-box-shadow: 0 0 60px 12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0 0 60px 12px rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 60px 12px rgba(0, 0, 0, 0.75);

    td {
      padding-left: 40px;
    }

    th {
      font-weight: bold;
      font-family: $cool-font-family;
      padding-left: 40px;
      padding-top: 50px;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      font-size: 13px;
      th {
        padding-left: 10px;
        padding-top: 10px;
        font-size: 12px;
      }
      td {
        padding-left: 10px;
      }
    }
  }

  &__edit-icon{
    cursor: pointer;
  }

  &__mobile-td {
    display: none;
  }
}
