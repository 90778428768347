@import '/../../../variables';

.eshop-menu-container {

  display: flex;
  flex-direction: column;
  color: $gertner-dark-blue;
  font-family: $cool-font-family;
  margin-top: 30px;
  justify-content: center;
  align-items: center;

  &__contacts {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    text-decoration-line: underline;
    a {
      text-decoration: none;
      color: $gertner-dark-blue;
      font-family: $cool-font-family;
    }
  }

  &__media {
    margin-top: 40px;
    a {
      padding: 10px;
    }
  }

 &__logo {
   margin-top: 120px;
   cursor: pointer;
 }

  &__navigation {
    width: 100%;
    margin-top: 93px;
    display: flex;
    flex-direction: column;
    color: $gertner-dark-blue;
    font-family: $cool-font-family;

    @media only screen and (max-width: $breakpoint-tablet){
      margin-right: 30%;
      margin-top: 50px;
    }

    li {
      display: flex;
    }
    a {
      text-decoration: none;
      color: $gertner-dark-blue;
    }
    ul {
      display: flex;
      list-style-type: none;
      overflow: hidden;

      @media only screen and (max-width: $breakpoint-tablet){
        flex-direction: column;
      }
    }

    li span {
      cursor: pointer;
      padding: 15px;
      font-style: italic;
      font-weight: 900;
      font-size: 21px;
      line-height: 36px;

    }

    li span:hover{
      text-decoration: underline;
    }

  }

}
