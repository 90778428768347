@import "/../../../variables";

.product-cart-iterator-container {

  max-width: 190px;
  display: flex;
  &__btn {
    width: 27px;
    height: 27px;
    text-decoration: none;
    background-color: #FFFFFF;
    border: 2px solid #C4C4C4;
    color: #C4C4C4;
    font-size: 18px;
  }

  &__text {
    display: inline-block;
    width: 75px;
    font-family: $cool-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 28px;
    margin-left: 25px;
    margin-right: 25px;
    color: $gertner-dark-blue;
  }

  &__input {
    max-width: 40px;
    margin-left: 5%;
    display: inline-block;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
