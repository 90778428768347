@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Montserrat+Alternates&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img,
svg,
video {
  vertical-align: middle;
}
img,
video {
  /* 1 */
  max-width: 100%;
  /* 2 */
  height: auto;
  /* 3 */
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.react-icons {
    vertical-align: middle;
}

.toast-wrapper {
  height: 0;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title-page {
  font-size: 30px;
}
@media only screen and (max-width: 560px) {
  .title-page {
    font-size: 20px;
  }
}

.error-validation {
  color: #d32533;
  font-size: 16px;
  font-weight: bold;
}

.admin-margin {
  margin-top: 20px;
  margin-bottom: 20px;
}

.admin-modal-center {
  display: flex;
  justify-content: center;
}

.admin-login-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 100px;
}

.admin-bold {
  font-weight: bold;
}
.admin-navbar {
  display: flex;
  flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  justify-content: center;
  flex-wrap: wrap;
  top: 0;
  z-index: 2;
}
@media only screen and (max-width: 560px) {
  .admin-navbar {
    position: unset;
  }
}
.admin-navbar__link {
  color: #282c34 !important;
}
@media only screen and (max-width: 560px) {
  .admin-navbar__link {
    min-height: 30px !important;
    height: 10px !important;
    line-height: 10px;
    flex-wrap: wrap;
  }
}
.admin-navbar__link:hover {
  box-shadow: inset 10px 10px 269px -130px rgb(67, 173, 173);
}
.admin-navbar__navigation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 15px;
}
.admin-navbar__navigation li {
  margin-left: 20px;
  list-style: none;
}
@media only screen and (max-width: 560px) {
  .admin-navbar__navigation {
    flex-direction: column;
  }
  .admin-navbar__navigation li {
    height: 30px;
    padding-top: 10px;
  }
  .admin-navbar__navigation a {
    height: 30px;
  }
}
.admin-navbar__logout-btn {
  margin-left: 20px;
}
@media only screen and (max-width: 560px) {
  .admin-navbar__logout-btn {
    margin-top: 25px;
  }
}
.admin-navbar__list {
  background-color: #C4C4C4;
  box-shadow: 0 8px 6px -6px black;
  color: #343434;
}
.admin-navbar__badge {
  animation: pulse 0.8s ease-in infinite;
}

@keyframes pulse {
  15% {
    transform: scale(1.2);
  }
  5% {
    transform: scale(1.2);
  }
}
@media only screen and (max-width: 560px) {
  .order-popup-container {
    font-size: 14px;
  }
}
@media only screen and (max-width: 560px) {
  .order-popup-container__title {
    font-size: 16px !important;
  }
}
.order-statistics-container {
  font-family: "Montserrat";
  width: 300px;
  background: rgb(172, 172, 172);
  background: linear-gradient(308deg, rgb(172, 172, 172) 17%, rgb(161, 161, 161) 33%, rgb(255, 254, 254) 51%, rgb(177, 177, 177) 84%, rgb(140, 142, 143) 95%);
  border-radius: 8px;
  margin: 1em;
  opacity: 0.9;
  box-shadow: 0 2px 16px 1px rgba(33, 33, 33, 0.5);
  padding: 2em;
  font-size: 15px;
  font-weight: bold;
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner-container__spin {
  color: #13444A;
}
.orders-container {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
}
.orders-container__table-block {
  max-width: 1280px;
}

.orders-container-mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}
.order-tile-container {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 50px;
  position: relative;
  box-shadow: 0 10px 6px -6px #777;
  background: rgb(203, 203, 203);
  background: radial-gradient(circle, rgba(203, 203, 203, 0.8309524493) 0%, rgb(200, 197, 197) 100%);
  padding: 25px;
}
.order-tile-container__label {
  font-weight: bold;
}
.order-tile-container__row {
  margin-top: 15px;
}
.order-tile-container__select {
  max-width: 150px;
}
.order-tile-container__confirm-btn {
  text-align: right;
}

.product-admin-container {
  font-family: "Montserrat";
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.product-admin-container__table {
  max-width: 1024px;
  margin-top: 50px;
  box-shadow: 0 0 60px 12px rgba(0, 0, 0, 0.75);
}
.product-admin-container__table td {
  padding-left: 40px;
}
.product-admin-container__table th {
  font-weight: bold;
  font-family: "Montserrat";
  padding-left: 40px;
  padding-top: 50px;
}
@media only screen and (max-width: 560px) {
  .product-admin-container__table {
    font-size: 13px;
  }
  .product-admin-container__table th {
    padding-left: 10px;
    padding-top: 10px;
    font-size: 12px;
  }
  .product-admin-container__table td {
    padding-left: 10px;
  }
}
.product-admin-container__edit-icon {
  cursor: pointer;
}
.product-admin-container__mobile-td {
  display: none;
}
.product-admin-container__search-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100%;
  border: 0;
  background: #fff;
  display: block;
  width: 500px;
  padding: 10px 32px 10px 70px;
  font-size: 24px;
  color: #666;
  border-radius: 3px;
  margin-top: 20px;
  box-shadow: 0px 3px 20px 1px rgba(8, 4, 4, 0.75);
}
@media only screen and (max-width: 560px) {
  .product-admin-container__search-bar {
    font-size: 18px;
    width: 260px;
    margin-right: 40px;
  }
}
.product-admin-container__search-bar:focus {
  box-shadow: 0px 3px 20px 1px rgba(11, 26, 39, 0.9);
}
.product-admin-container__new-btn {
  color: rgba(40, 44, 52, 0.77);
  font-weight: bolder;
  cursor: pointer;
}
.add-box:hover {
  box-shadow: -4px 3px 300px 39px rgb(43, 36, 43);
}
.product-medal-modal {
  display: flex;
}
.product-medal-modal__row {
  display: flex;
  border-top: 1px solid black;
  padding: 15px;
}
.product-medal-modal__row--label {
  display: flex;
  font-weight: bold;
  width: 90%;
}
*, *:after, *:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.toast-component {
  display: flex;
  position: fixed;
  width: 250px;
  height: 130px;
  border-radius: 8px;
  float: left;
  margin: 1em;
  opacity: 0;
  box-shadow: 0 2px 16px 1px rgba(33, 33, 33, 0.5) !important;
  padding: 15px;
}
.toast-component__hidden {
  display: none;
}
.toast-component__title {
  font-size: 15px;
  padding-left: 10px;
  margin-bottom: 0.7em;
  font-weight: bold;
  padding-top: 5px;
}
.toast-component__text {
  font-size: 12px;
  font-weight: bold;
  font-family: sans-serif;
  text-align: center;
  color: rgba(8, 4, 4, 0.82);
}
.toast-container {
  z-index: 5;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.toast-container__hidden {
  display: none;
}

.admin-dashboard {
  font-family: "Montserrat";
}
.medal-admin-container {
  font-family: "Montserrat";
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.medal-admin-container__table {
  max-width: 1024px;
  margin-top: 50px;
  box-shadow: 0 0 60px 12px rgba(0, 0, 0, 0.75);
}
.medal-admin-container__table td {
  padding-left: 40px;
}
.medal-admin-container__table th {
  font-weight: bold;
  font-family: "Montserrat";
  padding-left: 40px;
  padding-top: 50px;
}
@media only screen and (max-width: 560px) {
  .medal-admin-container__table {
    font-size: 13px;
  }
  .medal-admin-container__table th {
    padding-left: 10px;
    padding-top: 10px;
    font-size: 12px;
  }
  .medal-admin-container__table td {
    padding-left: 10px;
  }
}
.medal-admin-container__edit-icon {
  cursor: pointer;
}
.medal-admin-container__mobile-td {
  display: none;
}
.order-stats-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.event-admin-container {
  font-family: "Montserrat";
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.event-admin-container__table {
  max-width: 1024px;
  margin-top: 50px;
  box-shadow: 0 0 60px 12px rgba(0, 0, 0, 0.75);
}
.event-admin-container__table td {
  padding-left: 40px;
}
.event-admin-container__table th {
  font-weight: bold;
  font-family: "Montserrat";
  padding-left: 40px;
  padding-top: 50px;
}
@media only screen and (max-width: 560px) {
  .event-admin-container__table {
    font-size: 13px;
  }
  .event-admin-container__table th {
    padding-left: 10px;
    padding-top: 10px;
    font-size: 12px;
  }
  .event-admin-container__table td {
    padding-left: 10px;
  }
}
.event-admin-container__edit-icon {
  cursor: pointer;
}
.event-admin-container__mobile-td {
  display: none;
}
.orders-container {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
}
.orders-container__table-block {
  max-width: 1280px;
}

.orders-container-mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}
.video-clipp {
  animation: circle 3s;
  -webkit-clip-path: polygon(25% 0, 0 25%, 25% 50%, 0 75%, 25% 100%, 50% 75%, 75% 100%, 100% 75%, 75% 50%, 100% 25%, 75% 0, 50% 25%);
          clip-path: polygon(25% 0, 0 25%, 25% 50%, 0 75%, 25% 100%, 50% 75%, 75% 100%, 100% 75%, 75% 50%, 100% 25%, 75% 0, 50% 25%);
}

@keyframes circle {
  0% {
    -webkit-clip-path: polygon(12% 0, 0% 20%, 6% 48%, 0 72%, 9% 100%, 48% 95%, 89% 100%, 100% 72%, 96% 49%, 100% 20%, 89% 0, 49% 6%);
            clip-path: polygon(12% 0, 0% 20%, 6% 48%, 0 72%, 9% 100%, 48% 95%, 89% 100%, 100% 72%, 96% 49%, 100% 20%, 89% 0, 49% 6%);
  }
  10% {
    -webkit-clip-path: polygon(30% 0, 0% 20%, 12% 48%, 0 72%, 20% 100%, 49% 88%, 76% 100%, 100% 72%, 88% 49%, 100% 20%, 72% 0, 49% 14%);
            clip-path: polygon(30% 0, 0% 20%, 12% 48%, 0 72%, 20% 100%, 49% 88%, 76% 100%, 100% 72%, 88% 49%, 100% 20%, 72% 0, 49% 14%);
  }
  100% {
    -webkit-clip-path: polygon(25% 0, 0 25%, 25% 50%, 0 75%, 25% 100%, 50% 75%, 75% 100%, 100% 75%, 75% 50%, 100% 25%, 75% 0, 50% 25%);
            clip-path: polygon(25% 0, 0 25%, 25% 50%, 0 75%, 25% 100%, 50% 75%, 75% 100%, 100% 75%, 75% 50%, 100% 25%, 75% 0, 50% 25%);
  }
}
.header-container {
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
}
@media only screen and (max-width: 560px) {
  .header-container {
    margin-top: 95px;
  }
}
.header-container__description {
  cursor: pointer;
  justify-content: center;
  margin-top: 60px;
}
.user-dashboard-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.user-dashboard-container__events {
  max-width: 1440px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.user-dashboard-container__element {
  display: flex;
  width: 100%;
  justify-content: center;
}
.intro-container {
  max-width: 1440px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 48px;
  text-align: center;
}
.intro-container__text-title {
  max-width: 336px;
  display: flex;
  font-family: "Montserrat";
  font-weight: 900;
  font-style: italic;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: #13444A;
}
.intro-container__text-description {
  font-family: "Montserrat";
  max-width: 460px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin-top: 36px;
  color: #13444A;
}
.intro-container__btns {
  display: flex;
  text-align: center;
  align-items: center;
  margin-top: 50px;
}
@media only screen and (max-width: 560px) {
  .intro-container__btns {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.intro-container__button {
  margin-left: 20px;
}
.intro-container__small_nav {
  display: flex;
  flex-direction: column;
  margin-top: 33px;
}
.intro-container__small_nav span {
  cursor: pointer;
  font-style: italic;
  font-weight: 900;
  font-size: 21px;
  line-height: 36px;
  color: #13444A;
}
.intro-container__small_nav span:hover {
  text-decoration: underline;
}
.intro-container__image {
  width: 940px;
  height: 459px;
  background: url(/static/media/gertner-ruka.36c93f39.png);
}
@media only screen and (max-width: 1024px) {
  .intro-container__image {
    max-width: 650px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
@media only screen and (max-width: 560px) {
  .intro-container__image {
    background-image: url(/static/media/gertner-ruka-mobile.b931f99e.png);
    width: 361px;
    height: 176px;
  }
}

.photo-banner-component {
  margin-top: 141px;
  margin-bottom: 141px;
}
@media only screen and (max-width: 560px) {
  .photo-banner-component {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.gertner-button {
  border: 3px solid #13444A;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #13444A;
  width: 300px;
  padding: 10px 22px;
  text-align: center;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;
  font-size: 21px;
  line-height: 26px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
}

.gertner-button:hover {
  background-color: #13444A;
  color: #ffffff;
}

.gertner-button-mobile {
  border: 3px solid #13444A;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #13444A;
  max-width: 143px;
  padding: 10px 32px;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
}

.gertner-button-mobile:hover {
  background-color: #13444A;
  color: #ffffff;
}
.our-place-container {
  max-width: 1440px;
  width: 100%;
  position: relative;
}
@media only screen and (max-width: 560px) {
  .our-place-container {
    width: 375px;
  }
}
.our-place-container__grey-bcg {
  height: 280px;
  background-color: #F7F7F7;
}
.our-place-container__text {
  max-width: 350px;
  margin-left: 20%;
  margin-top: 13%;
  z-index: 10;
}
@media only screen and (max-width: 560px) {
  .our-place-container__text {
    margin-left: 20px;
    margin-top: 50px;
  }
}
.our-place-container__title {
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 36px;
  color: #13444A;
  font-family: "Montserrat";
  text-align: justify;
}
.our-place-container__description {
  text-align: left;
  color: #13444A;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  font-family: "Montserrat";
  margin-top: 14px;
}
.our-place-container__cross-image {
  position: absolute;
  right: 730px;
  top: 20px;
  z-index: 2;
}
@media only screen and (max-width: 560px) {
  .our-place-container__cross-image {
    top: 0;
    right: 0;
  }
}
.our-place-container__mill {
  height: 280px;
  background-image: url(/static/media/mlyn.6b0d2f44.png);
  background-size: contain;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 560px) {
  .our-place-container__mill {
    height: 211px;
    background-image: url(/static/media/mlyn-mobile.e1821c1f.png);
  }
}
.motorbike-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.motorbike-container .slide {
  background-color: #FFFFFF;
}

.slideshow-text {
  background-color: #ffffff;
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #13444A;
  margin-top: 30px;
  padding-left: 90px;
  padding-right: 90px;
  padding-top: 10px;
  height: 250px;
}
@media only screen and (max-width: 560px) {
  .slideshow-text {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.slideshow-image {
  padding: 20px;
}

.slideshow-container {
  background-color: #F7F7F7;
}

.slideshow-splitter {
  width: 103px;
  height: 15px;
}

.motorbike-title {
  width: 338px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 89px;
  margin-bottom: 35px;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #13444A;
}
.rychard-container {
  width: 1440px;
  height: 769px;
  background-image: url(/static/media/lahev-richard.f6af2565.png);
  font-family: "Montserrat";
  color: #FFFFFF;
  text-align: left;
}
.rychard-container__text {
  display: flex;
  flex-direction: column;
  border-top: 3px solid #FFFFFF;
  width: 300px;
  margin-top: 100px;
  margin-left: 250px;
}
.rychard-container__title {
  margin-top: 20px;
  margin-bottom: 20px;
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 36px;
}
.rychard-container__description {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  text-align: left;
}

.rychard-mobile-container {
  background: linear-gradient(315deg, transparent 100px, #171413 0) bottom right;
  max-width: 1440px;
  width: 100%;
}
.rychard-mobile-container__bcg {
  height: 200px;
  background-image: url(/static/media/lahev-richard.f6af2565.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}
.rychard-mobile-container__text {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  color: #FFFFFF;
  margin-top: 40px;
  padding-top: 10px;
  text-align: left;
  padding-left: 40px;
  font-family: "Montserrat";
  border-top: 3px solid #FFFFFF;
  padding-bottom: 40px;
}
.rychard-mobile-container__title {
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 36px;
}
.rychard-mobile-container__description {
  padding-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
}
.photo-banner-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1024px) {
  .photo-banner-container {
    width: 600px;
  }
}
@media only screen and (max-width: 560px) {
  .photo-banner-container {
    width: 375px;
  }
}
.photo-banner-container__image {
  cursor: pointer;
  width: 25%;
}
@media only screen and (max-width: 1024px) {
  .photo-banner-container__image {
    width: 300px;
    height: 300px;
  }
}
@media only screen and (max-width: 560px) {
  .photo-banner-container__image {
    width: 50%;
    height: 185px;
  }
}
.an-zwei-container {
  width: 1440px;
  height: 769px;
  background-image: url(/static/media/lahev-anzwei.3468e622.png);
  font-family: "Montserrat";
  color: #FFFFFF;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: 100px;
}
@media only screen and (max-width: 1024px) {
  .an-zwei-container {
    max-width: 650px;
    background-size: cover;
    background-repeat: no-repeat;
    color: #FFFFFF;
  }
}
.an-zwei-container__list {
  width: 360px;
  margin-top: 20px;
  color: #ECDFAB;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
}
.an-zwei-container__list ul {
  list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAASCAMAAACO0hVbAAAAD1BMVEXs4Kz//8xHcEz//8bs4KwHi0ghAAAABXRSTlOwBQAJ5n3ZApMAAABNSURBVBjTfZBbDgAhCANHdu9/Zt8Vk0a/yogFC5TIpwDlhr0kLjgKIsMpiQSXIOl9x2lQOzL6ZIvcf016MPPWzDC7mJ3N30wGLiuTaQVczwHv+5fTmQAAAABJRU5ErkJggg==);
}
.an-zwei-container__list li {
  padding-top: 10px;
  padding-left: 20px;
}
@media only screen and (max-width: 560px) {
  .an-zwei-container__list {
    width: 250px;
  }
}
.an-zwei-container__pattern {
  margin-left: 215px;
}
.an-zwei-container__text {
  flex-direction: column;
  border-top: 3px solid #FFFFFF;
  width: 300px;
  margin-left: 136px;
}
.an-zwei-container__title {
  display: flex;
  width: 200px;
  padding-top: 20px;
  margin-bottom: 20px;
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 36px;
}
.an-zwei-container__description {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  text-align: left;
}

.an-zwei-mobile-container {
  background-color: #171413;
}
.an-zwei-mobile-container__bcg {
  display: flex;
  max-width: 775px;
  width: 100%;
  height: 200px;
  background-image: url(/static/media/lahev-anzwei.3468e622.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.an-zwei-mobile-container__text {
  color: #FFFFFF;
  margin-top: 40px;
  padding-top: 10px;
  text-align: left;
  max-width: 700px;
  padding-left: 40px;
  font-family: "Montserrat";
  border-top: 3px solid #FFFFFF;
  padding-bottom: 40px;
}
.an-zwei-mobile-container__title {
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 36px;
}
.an-zwei-mobile-container__description {
  padding-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
}
.top-three-product-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat";
}
.top-three-product-container__products {
  display: flex;
  flex-direction: row;
  margin-top: 90px;
  justify-content: space-evenly;
}
@media only screen and (max-width: 1024px) {
  .top-three-product-container__product {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.top-three-product-container__link {
  margin-top: 42px;
  font-style: italic;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #13444A;
  cursor: pointer;
}
.top-three-product-container__link:hover {
  text-decoration: underline;
  color: #13444A;
}
.loading-dashboard-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-dashboard-container__spin {
  color: #343434;
}
.product-view-container {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  color: #13444A;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 560px) {
  .product-view-container {
    min-width: 0;
  }
}
.product-view-container__image {
  cursor: pointer;
}
.product-view-container__name {
  cursor: pointer;
  font-style: italic;
  font-weight: 900;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #13444A;
}
@media only screen and (max-width: 560px) {
  .product-view-container__name {
    font-size: 18px;
    line-height: 28px;
  }
}
.product-view-container__name:hover {
  color: #13444A;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.product-view-container__category {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
}
@media only screen and (max-width: 560px) {
  .product-view-container__category {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }
}
.product-view-container__button {
  min-width: 150px;
}
.event-header-container {
  display: flex;
  flex-direction: column;
  background-image: url(/static/media/event_background.ba443378.png);
  width: 1440px;
  height: 540px;
  background-size: cover;
  font-family: "Montserrat";
  color: #343434;
}
@media only screen and (max-width: 1024px) {
  .event-header-container {
    width: 700px;
    background-size: cover;
    background-repeat: no-repeat;
    height: 540px;
  }
}
@media only screen and (max-width: 560px) {
  .event-header-container {
    background-image: url(/static/media/event_background-mobile.524ec2dc.png);
    width: 375px;
    height: 539px;
  }
}
.event-header-container__title {
  max-width: 358px;
  margin-left: 254px;
  margin-top: 181px;
  font-style: italic;
  font-weight: 900;
  font-size: 48px;
  line-height: 48px;
}
@media only screen and (max-width: 1024px) {
  .event-header-container__title {
    margin-left: 150px;
  }
}
@media only screen and (max-width: 560px) {
  .event-header-container__title {
    max-width: 301px;
    margin-top: 50px;
    margin-left: 37px;
    font-size: 36px;
    line-height: 36px;
  }
}
.event-header-container__sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  max-width: 358px;
  margin-left: 254px;
  margin-top: 21px;
}
@media only screen and (max-width: 1024px) {
  .event-header-container__sub-title {
    margin-left: 150px;
  }
}
@media only screen and (max-width: 560px) {
  .event-header-container__sub-title {
    margin-top: 10px;
    margin-left: 37px;
    max-width: 143px;
  }
}
.event-dashboard-content {
  max-width: 1440px;
  height: auto;
  padding-bottom: 91px;
  background-color: #474747;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 560px) {
  .event-dashboard-content {
    width: 375px;
  }
}
.event-dashboard-content__container {
  display: flex;
  max-width: 900px;
  flex-flow: row wrap;
}
@media only screen and (max-width: 560px) {
  .event-dashboard-content__container {
    width: 375px;
    flex-direction: column;
    height: auto;
  }
}
.event-dashboard-content__item {
  display: flex;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 50px;
}
@media only screen and (max-width: 560px) {
  .event-dashboard-content__item {
    padding-bottom: 60px;
  }
}
.event-dashboard-content__next-events {
  width: 135px;
  font-style: italic;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #ECDFAB;
  margin-left: 890px;
  margin-top: 70px;
}
@media only screen and (max-width: 560px) {
  .event-dashboard-content__next-events {
    width: 120px;
    margin-left: 60px;
    margin-top: 30px;
  }
}
.event-dashboard-content__next-events:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  cursor: pointer;
}
.event-item-container {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  color: #FFFFFF;
  max-width: 300px;
}
.event-item-container__title {
  font-style: italic;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: flex-end;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.event-item-container__title a {
  text-decoration: none;
  color: #FFFFFF;
}
.event-item-container__date {
  font-style: normal;
  font-weight: 900;
  font-size: 21px;
  line-height: 28px;
  margin-top: 34px;
}
.event-item-container__text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #C4C4C4;
  margin-top: 12px;
}
.event-item-container__venue {
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
}
.tasting-container {
  max-width: 1440px;
  width: 100%;
  height: 656px;
  background-image: url(/static/media/degustace.ed74a931.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
}
@media only screen and (max-width: 560px) {
  .tasting-container {
    width: 375px;
    height: 656px;
    background-image: url(/static/media/degustace-mobile.39977f45.png);
  }
}
.tasting-container__content {
  margin-top: 164px;
  margin-left: 150px;
}
@media only screen and (max-width: 560px) {
  .tasting-container__content {
    margin-top: 300px;
    margin-left: 30px;
  }
}
.tasting-container__title {
  font-style: italic;
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
  text-align: justify;
  color: #C4C4C4;
}
.tasting-container__text {
  max-width: 300px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #FFFFFF;
  margin-top: 15px;
}
.tasting-container__btn {
  margin-top: 40px;
}
.tasting-container__button {
  font-family: "Montserrat";
  border: 2px solid #FFFFFF;
  padding: 10px;
  font-weight: 900;
  text-decoration: none;
  color: #FFFFFF;
}
.tasting-container__button:hover {
  text-decoration: underline;
  color: #FFFFFF;
}
.contact-card-container {
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  max-width: 380px;
  color: #13444A;
}
@media only screen and (max-width: 560px) {
  .contact-card-container {
    width: 300px;
  }
}
.contact-card-container__name {
  width: 320px;
  margin-top: 60px;
  border-top: 4px solid #13444A;
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 36px;
  padding-top: 10px;
}
@media only screen and (max-width: 560px) {
  .contact-card-container__name {
    font-size: 30px;
    line-height: 36px;
  }
}
.contact-card-container__contacts {
  font-size: 18px;
  font-weight: 900;
}
.contact-card-container__contacts a {
  color: #13444A;
}
.contact-card-container__phone {
  margin-top: 20px;
}
.contact-card-container__text {
  margin-top: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
}
.contact-card-container__image {
  position: relative;
  display: inline-block;
}
.contact-card-container__image:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: linear-gradient(140deg, transparent 360px, #ffffff 0) top left;
}
@media only screen and (max-width: 560px) {
  .contact-card-container__image:after {
    background: linear-gradient(140deg, transparent 300px, #ffffff 0) top left;
  }
}
.contact-card-container__img {
  display: block;
}
@media only screen and (max-width: 560px) {
  .contact-card-container__img {
    width: 300px;
  }
}
.contact-container {
  font-family: "Montserrat";
  color: #13444A;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-container__title {
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 36px;
  margin-top: 50px;
}
.contact-container .contact-component {
  display: flex;
  flex-direction: row;
  margin-top: 100px;
}
@media only screen and (max-width: 1024px) {
  .contact-container .contact-component {
    flex-direction: column;
  }
}
.contact-container .contact-component__lenka {
  margin-top: 250px;
  margin-left: 100px;
}
@media only screen and (max-width: 1024px) {
  .contact-container .contact-component__lenka {
    margin-left: 0;
    margin-top: 100px;
  }
}
.contact-container .contact-panel {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  width: 100%;
}
@media only screen and (max-width: 560px) {
  .contact-container .contact-panel {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}
.contact-container .contact-panel__icons {
  width: 300px;
}
.contact-container .contact-panel__icons a {
  margin-left: 20px;
}
@media only screen and (max-width: 560px) {
  .contact-container .contact-panel__icons {
    width: auto;
    margin-top: 15px;
  }
  .contact-container .contact-panel__icons a {
    margin-left: 15px;
  }
}
.contact-container .contact-panel__subsidy {
  justify-content: center;
  align-content: center;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  font-size: 18px;
}
.contact-container .contact-panel__subsidy li {
  list-style: none;
}
.contact-container .contact-panel__subsidy span:hover {
  text-decoration: underline;
}
.contact-container .contact-panel__contacts {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  align-items: flex-end;
  width: 100%;
}
.contact-container .contact-panel__contacts a {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  color: #13444A;
}
.contact-container .contact-panel__contacts a:hover {
  text-decoration: underline;
}
.dashboard-navigation-container {
  display: flex;
  margin-top: 250px;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 560px) {
  .dashboard-navigation-container {
    margin-top: 150px;
  }
}
.dashboard-navigation-container__image {
  cursor: pointer;
}
.dashboard-navigation-container__menu {
  width: 100%;
  margin-top: 93px;
  display: flex;
  flex-direction: column;
  color: #13444A;
  font-family: "Montserrat";
}
@media only screen and (max-width: 560px) {
  .dashboard-navigation-container__menu {
    margin-right: 30%;
    margin-top: 50px;
  }
}
.dashboard-navigation-container__menu li {
  display: flex;
}
.dashboard-navigation-container__menu a {
  text-decoration: none;
  color: #13444A;
}
.dashboard-navigation-container__menu ul {
  display: flex;
  list-style-type: none;
  overflow: hidden;
}
@media only screen and (max-width: 560px) {
  .dashboard-navigation-container__menu ul {
    flex-direction: column;
  }
}
.dashboard-navigation-container__menu li span {
  cursor: pointer;
  padding: 15px;
  font-style: italic;
  font-weight: 900;
  font-size: 21px;
  line-height: 36px;
}
.dashboard-navigation-container__menu li span:hover {
  text-decoration: underline;
}
.cart-banner-container {
  display: flex;
  width: 100%;
  max-width: 1440px;
  justify-content: space-around;
  flex-wrap: wrap;
  color: #C4C4C4;
  font-family: "Montserrat";
  padding-top: 50px;
}
.cart-banner-container__contacts {
  display: flex;
  flex-direction: column;
}
.cart-banner-container__contacts a {
  color: #C4C4C4;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 28px;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}
.cart-banner-container__contacts a:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.cart-banner-container__cart-info {
  display: flex;
  font-style: italic;
  font-weight: 900;
  font-size: 18px;
  line-height: 28px;
  text-align: right;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  cursor: pointer;
}
.cart-banner-container__icon {
  margin-left: 26px;
}
.cart-banner-container__info--rest-bottle {
  font-family: "Montserrat";
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.eshop-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.eshop-container__shopping-cart {
  margin-top: 160px;
}
.product-list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1440px;
}
@media only screen and (max-width: 560px) {
  .product-list-container {
    max-width: 375px;
  }
}
.product-list-container__list {
  max-width: 970px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 66px;
}
@media only screen and (max-width: 560px) {
  .product-list-container__list {
    margin-top: 20px;
  }
}
.product-list-container__products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 90px;
}
@media only screen and (max-width: 560px) {
  .product-list-container__product {
    flex-basis: 50%;
  }
}
.product-list-container__filter {
  display: flex;
  flex-direction: row;
  margin-top: 60px;
}
@media only screen and (max-width: 560px) {
  .product-list-container__filter {
    flex-direction: column;
    margin-top: 10px;
  }
}

.filter-label {
  display: flex;
  flex-direction: row;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 36px;
  color: #13444A;
  margin-left: 60px;
}
@media only screen and (max-width: 560px) {
  .filter-label {
    margin-top: 20px;
  }
}
.filter-label__cross {
  cursor: pointer;
}
.filter-label__text {
  padding-left: 21px;
}
.eshop-header-container {
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  font-family: "Montserrat";
  color: #13444A;
}
@media only screen and (max-width: 560px) {
  .eshop-header-container {
    flex-wrap: wrap;
    max-width: 320px;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 560px) {
  .eshop-header-container {
    justify-content: center;
    align-items: center;
  }
}
.eshop-header-container__link {
  width: 100%;
}
.eshop-header-container__logo {
  margin-top: 100px;
  margin-left: 100px;
}
@media only screen and (max-width: 560px) {
  .eshop-header-container__logo {
    margin-left: 0;
  }
}
.eshop-header-container__hand {
  width: 612px;
  height: 421px;
  margin-left: 100px;
}
.eshop-header-container__hand--mobile {
  width: 327px;
  height: 225px;
  margin-top: 30px;
}
.eshop-header-container__bottom {
  margin-top: 88px;
  display: flex;
}
@media only screen and (max-width: 560px) {
  .eshop-header-container__bottom {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 560px) {
  .eshop-header-container__bottom {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 1024px) {
  .eshop-header-container__bottom {
    margin-left: 100px;
    align-items: center;
    justify-content: center;
  }
}
.eshop-header-container__description {
  width: 35%;
}
@media only screen and (max-width: 560px) {
  .eshop-header-container__description {
    width: 320px;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 560px) {
  .eshop-header-container__description {
    width: 520px;
  }
}
.eshop-header-container__title {
  font-style: italic;
  padding-right: 50px;
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
}
.eshop-header-container__text {
  margin-left: 100px;
  margin-top: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
}
.eshop-header-container__text span {
  font-weight: 900;
  font-style: italic;
  cursor: pointer;
}
.eshop-header-container__text span:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 560px) {
  .eshop-header-container__text {
    margin-left: 0;
  }
}
.eshop-header-container__btn {
  margin-top: 30px;
  margin-left: 100px;
}
@media only screen and (max-width: 560px) {
  .eshop-header-container__btn {
    margin-left: 0;
  }
}
.shopping-cart-container {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  width: 100%;
  max-width: 1000px;
}
@media only screen and (max-width: 1024px) {
  .shopping-cart-container {
    justify-content: center;
    align-items: center;
  }
}
.shopping-cart-container__title {
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 36px;
  color: #13444A;
}
@media only screen and (max-width: 560px) {
  .shopping-cart-container__title {
    padding: 20px;
  }
}
.shopping-cart-container__table {
  margin-top: 64px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.shopping-cart-container__cart-cell {
  cursor: pointer;
  display: flex;
  width: 200px;
}
.shopping-cart-container__cart-cell a {
  color: #13444A;
  text-decoration: none;
}
@media only screen and (max-width: 1024px) {
  .shopping-cart-container__cart-cell {
    margin-top: 10px;
    width: 200px;
  }
}
.shopping-cart-container__cart-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #13444A;
  padding-top: 27px;
}
.shopping-cart-container__cart-row--name {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 28px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.shopping-cart-container__cart-row--vintage {
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 28px;
}
.shopping-cart-container__cart-row--price {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 28px;
  padding-left: 50px;
}
@media only screen and (max-width: 1024px) {
  .shopping-cart-container__cart-row {
    flex-direction: column;
    align-items: center;
    width: 500px;
  }
}
@media only screen and (max-width: 560px) {
  .shopping-cart-container__cart-row {
    width: 250px;
  }
}
.shopping-cart-container__cart-rest {
  margin-top: 29px;
  font-style: italic;
  font-weight: normal;
  font-size: 21px;
  line-height: 28px;
  color: #C4C4C4;
}
@media only screen and (max-width: 1024px) {
  .shopping-cart-container__cart-rest {
    font-style: italic;
    font-size: 19px;
    padding: 10px;
    line-height: 28px;
  }
}
.shopping-cart-container__sum {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 30px;
  margin-top: 30px;
  border-top: 3px solid #13444A;
  border-bottom: 3px solid #13444A;
}
.shopping-cart-container__cart-sum {
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 36px;
  color: #13444A;
}
@media only screen and (max-width: 1024px) {
  .shopping-cart-container__cart-sum {
    font-size: 28px;
  }
}
.shopping-cart-container__no-vat {
  color: #474747;
  text-align: right;
  font-size: 18px;
}
.shopping-cart-container__voucher-title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #13444A;
  cursor: pointer;
}
.shopping-cart-container__voucher-title:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.shopping-cart-container__voucher-btn-wrap {
  margin-left: 20px;
  display: flex;
  align-items: flex-end;
}
.shopping-cart-container__voucher-btn {
  border: 2px solid #13444A;
  background-color: #FFFFFF;
  color: #13444A;
  display: inline-block;
  width: 150px;
  height: 50px;
  font-family: "Montserrat";
  font-weight: 900;
}
@media only screen and (max-width: 1024px) {
  .shopping-cart-container__voucher-btn {
    margin-top: 20px;
  }
}
.shopping-cart-container__voucher-btn:hover {
  background-color: #13444A;
  color: #FFFFFF;
}
.shopping-cart-container__voucher-form {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}
@media only screen and (max-width: 1024px) {
  .shopping-cart-container__voucher-form {
    flex-direction: column;
    align-items: center;
  }
}
.shopping-cart-container__voucher-form--hidden {
  display: none;
}
.shopping-cart-container__voucher-error {
  color: #d32533;
}
.shopping-cart-container__order-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}
.shopping-cart-container__order-confirm-btn {
  background-color: transparent;
  border: 2px solid #13444A;
  color: #13444A;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;
  font-size: 21px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px 60px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.shopping-cart-container__order-confirm-btn:hover {
  background-color: #13444A;
  color: #FFFFFF;
}
.product-cart-iterator-container {
  max-width: 190px;
  display: flex;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.product-cart-iterator-container__btn {
  width: 27px;
  height: 27px;
  text-decoration: none;
  background-color: #FFFFFF;
  border: 2px solid #C4C4C4;
  color: #C4C4C4;
  font-size: 18px;
}
.product-cart-iterator-container__text {
  display: inline-block;
  width: 75px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 28px;
  margin-left: 25px;
  margin-right: 25px;
  color: #13444A;
}
.product-cart-iterator-container__input {
  max-width: 40px;
  margin-left: 5%;
  display: inline-block;
}
.product-cart-iterator-container input::-webkit-outer-spin-button,
.product-cart-iterator-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.product-cart-iterator-container input[type=number] {
  -moz-appearance: textfield;
}
.gertner-input-container {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  color: #13444A;
}
@media only screen and (max-width: 560px) {
  .gertner-input-container {
    width: 250px;
  }
}
.gertner-input-container__label {
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 27px;
}
.gertner-input-container__input {
  border: 2px solid #13444A;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 23px;
  font-family: "Montserrat";
  color: #13444A;
  font-style: italic;
  font-weight: 900;
  font-size: 21px;
  line-height: 27px;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 560px) {
  .gertner-input-container__input {
    font-size: 18px;
  }
}
.gertner-input-container__input--nonvalid {
  border: 2px solid #d32533;
  color: #d32533;
}
.eshop-menu-container {
  display: flex;
  flex-direction: column;
  color: #13444A;
  font-family: "Montserrat";
  margin-top: 30px;
  justify-content: center;
  align-items: center;
}
.eshop-menu-container__contacts {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.eshop-menu-container__contacts a {
  text-decoration: none;
  color: #13444A;
  font-family: "Montserrat";
}
.eshop-menu-container__media {
  margin-top: 40px;
}
.eshop-menu-container__media a {
  padding: 10px;
}
.eshop-menu-container__logo {
  margin-top: 120px;
  cursor: pointer;
}
.eshop-menu-container__navigation {
  width: 100%;
  margin-top: 93px;
  display: flex;
  flex-direction: column;
  color: #13444A;
  font-family: "Montserrat";
}
@media only screen and (max-width: 560px) {
  .eshop-menu-container__navigation {
    margin-right: 30%;
    margin-top: 50px;
  }
}
.eshop-menu-container__navigation li {
  display: flex;
}
.eshop-menu-container__navigation a {
  text-decoration: none;
  color: #13444A;
}
.eshop-menu-container__navigation ul {
  display: flex;
  list-style-type: none;
  overflow: hidden;
}
@media only screen and (max-width: 560px) {
  .eshop-menu-container__navigation ul {
    flex-direction: column;
  }
}
.eshop-menu-container__navigation li span {
  cursor: pointer;
  padding: 15px;
  font-style: italic;
  font-weight: 900;
  font-size: 21px;
  line-height: 36px;
}
.eshop-menu-container__navigation li span:hover {
  text-decoration: underline;
}
.product-detail-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.product-detail-container__back-to-list {
  font-family: "Montserrat";
  font-weight: 900;
  max-width: 1000px;
  width: 100%;
  margin-top: 50px;
  display: flex;
  color: #13444A;
  font-size: 20px;
  cursor: pointer;
  font-style: italic;
  text-decoration: underline;
}
.product-detail-container__cart {
  margin-top: 285px;
}
.product-detail-container__similar-products {
  font-family: "Montserrat";
  width: 300px;
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 36px;
  color: #C4C4C4;
  border-top: 2px solid #C4C4C4;
  padding-top: 20px;
  margin-left: 20px;
  margin-top: 40px;
}
.product-full-container {
  font-family: "Montserrat";
  display: flex;
  flex-direction: row;
  color: #13444A;
  margin-top: 52px;
  justify-content: center;
}
@media only screen and (max-width: 560px) {
  .product-full-container {
    flex-direction: column;
  }
}
@media only screen and (max-width: 560px) {
  .product-full-container__image {
    display: flex;
    justify-content: center;
  }
}
.product-full-container__description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}
@media only screen and (max-width: 560px) {
  .product-full-container__description {
    align-items: center;
  }
}
.product-full-container__color {
  font-style: italic;
  font-weight: 900;
  font-size: 18px;
  line-height: 36px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #C4C4C4;
}
.product-full-container__name {
  font-style: italic;
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
  margin-top: 11px;
}
@media only screen and (max-width: 560px) {
  .product-full-container__name {
    text-align: center;
  }
}
.product-full-container__vintage {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  margin-top: 7px;
}
.product-full-container__category {
  margin-top: 60px;
}
.product-full-container__category--label {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
}
.product-full-container__category--value {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
}
.product-full-container__text {
  margin-top: 37px;
  max-width: 546px;
}
.product-full-container__iterator {
  margin-top: 37px;
}
.product-full-container__info {
  display: flex;
  margin-top: 90px;
}
.product-full-container__params--label {
  margin-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
}
.product-full-container__params--value {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
}
.product-full-container__medals {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #C4C4C4;
  margin-left: 60px;
  margin-top: 20px;
}
.product-full-container__medals ul {
  list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAASCAMAAACO0hVbAAAAD1BMVEXs4Kz//8xHcEz//8bs4KwHi0ghAAAABXRSTlOwBQAJ5n3ZApMAAABNSURBVBjTfZBbDgAhCANHdu9/Zt8Vk0a/yogFC5TIpwDlhr0kLjgKIsMpiQSXIOl9x2lQOzL6ZIvcf016MPPWzDC7mJ3N30wGLiuTaQVczwHv+5fTmQAAAABJRU5ErkJggg==);
}
.product-iterator-container__btn {
  width: 27px;
  height: 27px;
  text-decoration: none;
  background-color: #FFFFFF;
  border: 2px solid #C4C4C4;
  color: #C4C4C4;
  font-size: 18px;
  text-align: center;
}
.product-iterator-container__text {
  color: #C4C4C4;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  margin-left: 23px;
  margin-right: 23px;
}
.eshop-toast-container {
  top: 0;
  position: -webkit-sticky; /* Safari */
  color: #13444A;
  font-family: "Montserrat";
  z-index: 1000;
}
.eshop-toast-container__toast {
  border: 1px solid #C4C4C4;
  max-width: 1200px;
}
.eshop-toast-container__title {
  font-weight: 400;
  font-style: italic;
}
.eshop-toast-container__product {
  text-decoration: underline;
}
.order-confirmation-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.order-confirmation-container__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.order-confirmation-container__link {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.order-confirmation-container__cart {
  margin-top: 160px;
}
.order-confirmation-container__delivery, .order-confirmation-container__payment-method {
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin-top: 120px;
}
@media only screen and (max-width: 1024px) {
  .order-confirmation-container__delivery, .order-confirmation-container__payment-method {
    margin-left: 10%;
  }
}
.order-confirmation-container__form {
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin-top: 120px;
}
.order-confirmation-container__eshop-menu {
  display: flex;
  margin-top: 200px;
  justify-content: center;
}
.delivery-container {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  width: 100%;
  max-width: 1000px;
}
@media only screen and (max-width: 560px) {
  .delivery-container {
    justify-content: center;
    align-items: center;
  }
}
.delivery-container__title {
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 36px;
  padding-left: 20px;
  color: #13444A;
}
.delivery-container__checkboxes {
  display: flex;
  margin-top: 50px;
}
@media only screen and (max-width: 560px) {
  .delivery-container__checkboxes {
    flex-direction: column;
  }
}

.top-trans-warning {
  margin-top: 50px;
  max-width: 450px;
  padding: 20px;
  color: #d32533;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.top-trans-warning__description {
  margin-top: 20px;
}
.top-trans-warning__account {
  font-weight: 900;
  font-style: italic;
}
.top-trans-warning span {
  font-weight: 900;
  font-style: italic;
}
.gertner-checkbox-container {
  display: flex;
  width: 300px;
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
}
.gertner-checkbox-container .container-checkbox {
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
  font-family: "Montserrat";
}
.gertner-checkbox-container .container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.gertner-checkbox-container .checkmark {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
  border: 2px solid #13444A;
}
.gertner-checkbox-container .checkmark--small {
  width: 30px;
  height: 30px;
}
.gertner-checkbox-container .container-checkbox .checkmark:after {
  width: 36px;
  height: 36px;
  left: 20%;
  right: 20%;
  top: 20%;
  bottom: 20%;
  border-width: 0 3px 3px 0;
  background-image: url(/static/media/cross-check.79d5a1b3.svg);
}
.gertner-checkbox-container .container-checkbox .checkmark--small:after {
  width: 15px;
  height: 15px;
  left: 23%;
  top: 23%;
  border-width: 0 3px 3px 0;
  background-image: url(/static/media/cross-check.79d5a1b3.svg);
  background-size: contain;
}
.gertner-checkbox-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.gertner-checkbox-container .container-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.gertner-checkbox-container__texts {
  display: flex;
  width: 300px;
  flex-direction: column;
  margin-left: 20%;
  padding-left: 20px;
}
.gertner-checkbox-container__title {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 28px;
  color: #13444A;
}
.gertner-checkbox-container__description {
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 28px;
}
.gertner-checkbox-container__price {
  font-size: 21px;
  line-height: 28px;
  color: #C4C4C4;
}
.order-form-container {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  max-width: 1440px;
  width: 100%;
}
@media only screen and (max-width: 560px) {
  .order-form-container {
    justify-content: center;
    align-items: center;
  }
}
.order-form-container__title {
  max-width: 320px;
  color: #13444A;
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 36px;
}
@media only screen and (max-width: 1024px) {
  .order-form-container__title {
    margin-left: 10%;
  }
}
.order-form-container__form {
  display: flex;
  flex-wrap: wrap;
  margin-top: 43px;
}
@media only screen and (max-width: 1024px) {
  .order-form-container__form {
    margin-left: 10%;
  }
}
@media only screen and (max-width: 560px) {
  .order-form-container__form {
    flex-direction: column;
  }
}
.order-form-container__form--item {
  margin-right: 40px;
  margin-top: 40px;
}
.order-form-container__error {
  width: 320px;
  color: #d32533;
  padding: 20px;
  font-style: italic;
  font-size: 18px;
}
.order-form-container__company-shopping {
  display: flex;
  margin-top: 60px;
}

.order-summary-container {
  background-color: #13444A;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  font-weight: normal;
  font-size: 21px;
  line-height: 36px;
  text-align: right;
  color: #FFFFFF;
  margin-top: 128px;
  padding-bottom: 61px;
}
@media only screen and (max-width: 560px) {
  .order-summary-container {
    justify-content: center;
  }
}
.order-summary-container__info {
  margin-right: 60px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.order-summary-container__summary {
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 36px;
  color: #ECDFAB;
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  max-width: 400px;
}
.order-summary-container__summary--title {
  border-top: 3px solid #ECDFAB;
  padding-top: 20px;
}
.order-summary-container__no-vat {
  font-size: 18px;
  font-style: normal;
}
.order-summary-container__finish-block {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}
@media only screen and (max-width: 1024px) {
  .order-summary-container__finish-block {
    align-items: center;
  }
}
.order-summary-container__finish-block--text {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  font-weight: normal;
  font-size: 21px;
  line-height: 36px;
  text-align: right;
  color: #13444A;
  margin-top: 60px;
  padding-right: 20px;
}
@media only screen and (max-width: 1024px) {
  .order-summary-container__finish-block--text {
    margin-right: 0;
  }
}
.order-summary-container__finish-block--text span {
  text-decoration: underline;
  cursor: pointer;
}
.order-summary-container__finish-block--buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 95px;
}
@media only screen and (max-width: 560px) {
  .order-summary-container__finish-block--buttons {
    flex-direction: column;
  }
}
.order-summary-container__finish-block--button {
  margin-left: 300px;
}
@media only screen and (max-width: 1024px) {
  .order-summary-container__finish-block--button {
    margin-left: 0;
  }
}
.order-summary-container__more-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  color: #13444A;
}
.order-summary-container__more-info--textarea {
  border: 2px solid #13444A;
  color: #13444A;
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 25px;
  max-width: 50%;
}
@media only screen and (max-width: 560px) {
  .order-summary-container__more-info--textarea {
    max-width: 100%;
  }
}
.order-summary-container__more-info--textarea-invalid {
  border: 2px solid #d32533;
}
.order-summary-container__more-info span {
  text-decoration: none;
  font-weight: normal;
  margin-left: 20px;
}
.order-summary-container__more-info--title label {
  display: flex;
  text-decoration: none;
  max-width: 320px;
  color: #13444A;
  font-style: italic;
  font-weight: 900;
  font-size: 26px;
  line-height: 36px;
}
.payment-method-container {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  width: 100%;
  max-width: 1000px;
}
@media only screen and (max-width: 560px) {
  .payment-method-container {
    justify-content: center;
    align-items: center;
  }
}
.payment-method-container__title {
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 36px;
  padding-left: 20px;
  color: #13444A;
}
.payment-method-container__selection {
  display: flex;
  margin-top: 50px;
}
@media only screen and (max-width: 560px) {
  .payment-method-container__selection {
    flex-direction: column;
  }
}
.order-complete-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "Montserrat";
}
.order-complete-container__header {
  display: flex;
  justify-content: center;
  margin-top: 90px;
}
.order-complete-container__header-image {
  display: flex;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(/static/media/complete-image.b2fde1a1.png);
  height: 490px;
  width: 1233px;
}
@media only screen and (max-width: 560px) {
  .order-complete-container__header-image {
    justify-content: center;
    align-items: center;
    height: 300px;
  }
}
.order-complete-container__title {
  color: #FFFFFF;
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 36px;
  text-align: right;
  max-width: 300px;
  margin-top: 20%;
  margin-left: 50%;
  border-top: 3px solid #FFFFFF;
  padding-top: 20px;
}
@media only screen and (max-width: 560px) {
  .order-complete-container__title {
    margin-left: 0;
    text-align: left;
    color: #13444A;
    margin-top: 55%;
    border-top: 2px solid #13444A;
  }
}
.order-complete-container__content {
  display: flex;
  justify-content: center;
}
.order-complete-container__text {
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 28px;
  color: #13444A;
  margin-top: 50px;
  border-bottom: 2px solid #13444A;
  padding: 10px;
}
.order-complete-container__row {
  display: flex;
  flex-wrap: wrap;
}
.order-complete-container__row--header {
  border-bottom: 2px solid #13444A;
  margin-bottom: 30px;
}
.order-complete-container__row--sum {
  border-top: 2px solid #13444A;
}
.order-complete-container__col {
  display: flex;
  padding: 10px;
}
.order-complete-container__col--bold {
  font-weight: 600;
  width: 300px;
}
.order-complete-container__col--cart {
  width: 150px;
}
.order-complete-container__col--no-vat {
  width: 300px;
  font-style: normal;
  font-weight: normal;
  color: rgba(71, 71, 71, 0.5098039216);
}
.order-complete-container__table {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #13444A;
  margin-top: 50px;
}
@media only screen and (max-width: 560px) {
  .order-complete-container__table {
    padding-left: 20px;
  }
}
.order-complete-container__cart-title {
  font-weight: 900;
  margin-top: 30px;
  border-bottom: 2px solid #13444A;
  margin-bottom: 20px;
}
.order-complete-container__buttons {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.order-complete-container__buttons-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.order-complete-container__link-parent {
  margin-top: 30px;
}
.order-complete-container__link {
  font-style: italic;
  font-weight: 900;
  font-size: 21px;
  line-height: 36px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #13444A;
  cursor: pointer;
}
.order-complete-container__eshop-menu {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.age-verification-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  width: 100%;
  flex-direction: column;
}
.age-verification-container__header {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  color: #13444A;
  font-family: "Montserrat";
  font-size: 16px;
  max-width: 360px;
  padding: 20px;
  text-align: center;
}
.age-verification-container__body {
  max-width: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-weight: 900;
  font-style: italic;
  line-height: 36px;
  text-align: center;
  color: #13444A;
  font-family: "Montserrat";
  margin-top: 30px;
}
@media only screen and (max-width: 560px) {
  .age-verification-container__body {
    font-size: 24px;
  }
}
.age-verification-container__button {
  max-width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 50px;
}
.error-component {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat";
}
.error-component__header {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: row;
}
@media only screen and (max-width: 1024px) {
  .error-component__header {
    flex-direction: column;
    align-items: center;
  }
}
.error-component__header-image {
  display: flex;
  background-image: url(/static/media/broken_bottle.c889ae1e.png);
  height: 325px;
  width: 311px;
}
@media only screen and (max-width: 560px) {
  .error-component__header-image {
    height: 162px;
    width: 156px;
    background-size: contain;
    justify-content: center;
    align-items: center;
  }
}
.error-component__title {
  max-width: 500px;
  color: #13444A;
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 36px;
  margin-top: 150px;
  text-align: right;
  display: flex;
}
@media only screen and (max-width: 560px) {
  .error-component__title {
    font-size: 18px;
    margin-left: 0;
    text-align: left;
    color: #13444A;
    border-top: 2px solid #13444A;
    padding: 20px;
    margin-top: 20px;
  }
}
.error-component__content {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 200px;
}
.error-component__link {
  font-style: italic;
  font-weight: 900;
  font-size: 21px;
  line-height: 36px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #13444A;
  cursor: pointer;
}
.pgw-redirect-container {
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding-top: 100px;
}
.pgw-redirect-container__description {
  margin-top: 20px;
  text-align: center;
}
.subsidy-modal-description-container {
  border-top: 2px solid #13444A;
  font-family: "Montserrat";
  color: #13444A;
  padding: 10px;
}
.subsidy-modal-description-container__title {
  font-weight: bold;
}
