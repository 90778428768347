@import "./../../variables";

.loading-dashboard-container {
  display: flex;
  align-items: center;
  justify-content: center;

  &__spin {
    color: $gertner-dark-grey;
  }
}
