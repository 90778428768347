@import '/../../variables';

.age-verification-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  width: 100%;
  flex-direction: column;
  &__header {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    color: $gertner-dark-blue;
    font-family: $cool-font-family;
    font-size: 16px;
    max-width: 360px;
    padding: 20px;
    text-align: center;
  }

  &__body {
    max-width: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-weight: 900;
    font-style: italic;
    line-height: 36px;
    text-align: center;
    color: $gertner-dark-blue;
    font-family: $cool-font-family;
    margin-top: 30px;
    @media only screen and (max-width: $breakpoint-tablet) {
      font-size: 24px;
    }
  }

  &__button {
    max-width: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
