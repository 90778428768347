@import "/../../../variables";

.contact-container {
  font-family: $cool-font-family;
  color: $gertner-dark-blue;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-style: italic;
    font-weight: 900;
    font-size: 36px;
    line-height: 36px;
    margin-top: 50px;
  }

  .contact-component {
    display: flex;
    flex-direction: row;
    margin-top: 100px;

    @media only screen and (max-width: $breakpoint-desktop) {
      flex-direction: column;
    }

    &__lenka {
      margin-top: 250px;
      margin-left: 100px;

      @media only screen and (max-width: $breakpoint-desktop) {
        margin-left: 0;
        margin-top: 100px;
      }
    }
  }

  .contact-panel {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-tablet) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }

    &__icons {
      width: 300px;
      a {
        margin-left: 20px;
      }
      @media only screen and (max-width: $breakpoint-tablet) {
        width: auto;
        margin-top: 15px;
        a {
          margin-left: 15px;
        }
      }
    }

    &__subsidy {
      li {
        list-style: none;
      }
      justify-content: center;
      align-content: center;
      cursor: pointer;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      font-size: 18px;

      span:hover{
        text-decoration: underline;
      }
    }

    &__contacts {
      display: flex;
      flex-direction: column;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      align-items: flex-end;
      width: 100%;
      a {
        text-decoration-line: none;
        color: $gertner-dark-blue;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}
