@import "./../../../variables";

.product-view-container {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  font-family: $cool-font-family;
  color: $gertner-dark-blue;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: $breakpoint-tablet) {
    min-width: 0;
  }

  &__image {
    cursor: pointer;
  }

  &__name {
    cursor: pointer;
    font-style: italic;
    font-weight: 900;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    color: $gertner-dark-blue;
    @media only screen and (max-width: $breakpoint-tablet) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  &__name:hover {
    color: $gertner-dark-blue;
    text-decoration-line: underline;
  }

  &__category {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-align: center;

    @media only screen and (max-width: $breakpoint-tablet) {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
    }
  }

  &__button {
    min-width: 150px;
  }
}
