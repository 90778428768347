@import '/../../../variables';

.contact-card-container {
  font-family: $cool-font-family;
  display: flex;
  flex-direction: column;
  max-width: 380px;
  color: $gertner-dark-blue;

  @media only screen and (max-width: $breakpoint-tablet){
    width: 300px;
  }

  &__name {
    width: 320px;
    margin-top: 60px;
    border-top: 4px solid $gertner-dark-blue;
    font-style: italic;
    font-weight: 900;
    font-size: 36px;
    line-height: 36px;
    padding-top: 10px;

    @media only screen and (max-width: $breakpoint-tablet) {
      font-size: 30px;
      line-height: 36px;
    }
  }

  &__contacts {
    font-size: 18px;
    font-weight: 900;
    a {
      color: $gertner-dark-blue;
    }
  }


  &__phone {
    margin-top: 20px;
  }

  &__text {
    margin-top: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;

  }

  &__image {
    position:relative;
    display:inline-block;
  }

  &__image:after {
    content:'';
    position:absolute;
    left:0; top:0;
    width:100%; height:100%;
    display:inline-block;
    background: linear-gradient(140deg, transparent 360px, #ffffff 0) top left;
    @media only screen and (max-width: $breakpoint-tablet){
      background: linear-gradient(140deg, transparent 300px, #ffffff 0) top left;
    }
  }

  &__img{
    display:block;
    @media only screen and (max-width: $breakpoint-tablet){
      width: 300px;
    }
  }

}
