@import "./../../../../variables";

.pgw-redirect-container {
  font-family: $cool-font-family;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding-top: 100px;

  &__description {
    margin-top: 20px;
    text-align: center;
  }
}
