@import "/../../variables";

.eshop-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  &__shopping-cart {
    margin-top: 160px;
  }
}
