@import "/../../variables";

.subsidy-modal-description-container {

  border-top: 2px solid $gertner-dark-blue;
  font-family: $cool-font-family;
  color: $gertner-dark-blue;
  padding: 10px;

  &__title {
    font-weight: bold;
  }
}
