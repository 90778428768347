@import "./../../../variables";

.our-place-container {
  max-width: 1440px;
  width: 100%;
  position: relative;

  @media only screen and (max-width: $breakpoint-tablet) {
    width: 375px;
  }

  &__grey-bcg {
    height: 280px;
    background-color: #F7F7F7;
  }

  &__text {
    max-width: 350px;
    margin-left: 20%;
    margin-top: 13%;
    z-index: 10;
    @media only screen and (max-width: $breakpoint-tablet) {
      margin-left: 20px;
      margin-top: 50px;
    }
  }

  &__title {
    font-style: italic;
    font-weight: 900;
    font-size: 36px;
    line-height: 36px;
    color: $gertner-dark-blue;
    font-family: $cool-font-family;
    text-align: justify;
  }

  &__description {
    text-align: left;
    color: $gertner-dark-blue;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    font-family: $cool-font-family;
    margin-top: 14px;
  }

  &__cross-image {
    position: absolute;
    right: 730px;
    top: 20px;
    z-index: 2;
    @media only screen and (max-width: $breakpoint-tablet) {
      top: 0;
      right: 0;
    }

  }

  &__mill {
    height: 280px;
    background-image: url("./../../../assets/images/mlyn.png");
    background-size: contain;
    background-repeat: no-repeat;
    @media only screen and (max-width: $breakpoint-tablet) {
      height: 211px;
      background-image: url("./../../../assets/images/mlyn-mobile.png");
    }
  }

}
