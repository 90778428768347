@import "./../../../../variables";

.rychard-container {
  width: 1440px;
  height: 769px;
  background-image: url("./../../../../assets/images/lahev-richard.png");
  font-family: $cool-font-family;
  color: #FFFFFF;
  text-align: left;

  &__text {
    display: flex;
    flex-direction: column;
    border-top: 3px solid #FFFFFF;
    width: 300px;
    margin-top: 100px;
    margin-left: 250px;
  }

  &__title {
    margin-top: 20px;
    margin-bottom: 20px;
    font-style: italic;
    font-weight: 900;
    font-size: 36px;
    line-height: 36px;
  }

  &__description {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
  }
}

.rychard-mobile-container {
  background: linear-gradient(315deg, transparent 100px, $gertner-dark-brown 0) bottom right;
  max-width: 1440px;
  width: 100%;
  &__bcg {
    height: 200px;
    background-image: url("./../../../../assets/images/lahev-richard.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }

  &__text {
    display: flex;
    flex-direction: column;
    max-width: 360px;
    color: #FFFFFF;
    margin-top: 40px;
    padding-top: 10px;
    text-align: left;
    padding-left: 40px;
    font-family: $cool-font-family;
    border-top: 3px solid #FFFFFF;
    padding-bottom: 40px;
  }

  &__title {
    font-style: italic;
    font-weight: 900;
    font-size: 36px;
    line-height: 36px;
  }

  &__description {
    padding-top: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
  }
}
