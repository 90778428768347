*, *:after, *:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.toast-component{
  display: flex;
  position: fixed;
  width: 250px;
  height: 130px;
  border-radius: 8px;
  float: left;
  margin: 1em;
  opacity: 0;
  box-shadow: 0 2px 16px 1px rgba(33, 33, 33, 0.5) !important;
  padding: 15px;

  &__hidden {
    display: none;
  }

  &__title{
    font-size: 15px;
    padding-left: 10px;
    margin-bottom: 0.7em;
    font-weight: bold;
    padding-top: 5px;
  }

  &__text {
    font-size: 12px;
    font-weight: bold;
    font-family: sans-serif;
    text-align: center;
    color: rgba(8, 4, 4, 0.82);
  }
}
