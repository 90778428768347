@import "./../../../../variables";

.an-zwei-container {
  width: 1440px;
  height: 769px;
  background-image: url("./../../../../assets/images/lahev-anzwei.png");
  font-family: $cool-font-family;
  color: #FFFFFF;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: 100px;

  @media only screen and (max-width: $breakpoint-desktop){
    max-width: 650px;
    background-size: cover;
    background-repeat: no-repeat;
    color: #FFFFFF;
  }

  &__list {
    width: 360px;
    margin-top: 20px;
    color: $gertner-gold;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    ul {
      list-style-image: url("./../../../../assets/images/odrazka-x-zluta.png");
    }
    li {
      padding-top: 10px;
      padding-left: 20px;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      width: 250px;
    }
  }

  &__pattern {
    margin-left: 215px;
  }

  &__text {
    flex-direction: column;
    border-top: 3px solid #FFFFFF;
    width: 300px;
    margin-left: 136px;
  }

  &__title {
    display: flex;
    width: 200px;
    padding-top: 20px;
    margin-bottom: 20px;
    font-style: italic;
    font-weight: 900;
    font-size: 36px;
    line-height: 36px;
  }

  &__description {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
  }
}

.an-zwei-mobile-container {
  background-color: $gertner-dark-brown;
  &__bcg {
    display: flex;
    max-width: 775px;
    width: 100%;
    height: 200px;
    background-image: url("./../../../../assets/images/lahev-anzwei.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__text {
    color: #FFFFFF;
    margin-top: 40px;
    padding-top: 10px;
    text-align: left;
    max-width: 700px;
    padding-left: 40px;
    font-family: $cool-font-family;
    border-top: 3px solid #FFFFFF;
    padding-bottom: 40px;
  }

  &__title {
    font-style: italic;
    font-weight: 900;
    font-size: 36px;
    line-height: 36px;
  }

  &__description {
    padding-top: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
  }
}
