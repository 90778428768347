@import "./../../../../variables";

.top-three-product-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: $cool-font-family;

  &__products {
    display: flex;
    flex-direction: row;
    margin-top: 90px;
    justify-content: space-evenly;
  }

  &__product {
    @media only screen and (max-width: $breakpoint-desktop) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  &__link {
    margin-top: 42px;
    font-style: italic;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: $gertner-dark-blue;
    cursor: pointer;
  }

  &__link:hover {
    text-decoration: underline;
    color: $gertner-dark-blue;
  }
}
