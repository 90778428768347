@import "/../../../variables";

.shopping-cart-container {
  display: flex;
  flex-direction: column;
  font-family: $cool-font-family;
  width: 100%;
  max-width: 1000px;
  @media only screen and (max-width: $breakpoint-desktop) {
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-style: italic;
    font-weight: 900;
    font-size: 36px;
    line-height: 36px;
    color: $gertner-dark-blue;

    @media only screen and (max-width: $breakpoint-tablet) {
        padding: 20px;
    }
  }

  &__table {
    margin-top: 64px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  &__cart-cell {
    cursor: pointer;
    display: flex;
    width: 200px;
    a {
      color: $gertner-dark-blue;
      text-decoration: none;
    }
    @media only screen and (max-width: $breakpoint-desktop) {
      margin-top: 10px;
      width: 200px;
    }
  }

  &__cart-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: $gertner-dark-blue;
    padding-top: 27px;
    &--name {
      font-style: normal;
      font-weight: bold;
      font-size: 21px;
      line-height: 28px;
      text-decoration-line: underline;
    }

    &--vintage {
      font-style: normal;
      font-weight: normal;
      font-size: 21px;
      line-height: 28px;
    }

    &--price {
      font-style: normal;
      font-weight: bold;
      font-size: 21px;
      line-height: 28px;
      padding-left: 50px;
    }


    @media only screen and (max-width: $breakpoint-desktop) {
      flex-direction: column;
      align-items: center;
      width: 500px;
    }
    @media only screen and (max-width: $breakpoint-tablet) {
      width: 250px;
    }
  }


  &__cart-rest {
    margin-top: 29px;
    font-style: italic;
    font-weight: normal;
    font-size: 21px;
    line-height: 28px;
    color: #C4C4C4;

    @media only screen and (max-width: $breakpoint-desktop) {
      font-style: italic;
      font-size: 19px;
      padding: 10px;
      line-height: 28px;
    }
  }

  &__sum {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 30px;
    margin-top: 30px;
    border-top: 3px solid $gertner-dark-blue;
    border-bottom: 3px solid $gertner-dark-blue;

  }

  &__cart-sum {
    font-style: italic;
    font-weight: 900;
    font-size: 36px;
    line-height: 36px;
    color: $gertner-dark-blue;
    @media only screen and (max-width: $breakpoint-desktop) {
      font-size: 28px;
    }
  }

  &__no-vat {
    color: $gertner-grey;
    text-align: right;
    font-size: 18px;
  }

  &__voucher-title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #13444A;
    cursor: pointer;
  }

  &__voucher-title:hover {
    text-decoration-line: underline;
  }


  &__voucher-btn-wrap {
    margin-left: 20px;
    display: flex;
    align-items: flex-end;
  }

  &__voucher-btn {
    border: 2px solid $gertner-dark-blue;
    background-color: #FFFFFF;
    color: $gertner-dark-blue;
    display: inline-block;
    width: 150px;
    height: 50px;
    font-family: $cool-font-family;
    font-weight: 900;

    @media only screen and (max-width: $breakpoint-desktop) {
      margin-top: 20px;
    }
  }

  &__voucher-btn:hover {
    background-color: $gertner-dark-blue;
    color: #FFFFFF;
  }

  &__voucher-form {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    @media only screen and (max-width: $breakpoint-desktop) {
      flex-direction: column;
      align-items: center;
    }

    &--hidden {
      display: none;
    }
  }

  &__voucher-error {
    color: $gertner-error-red;
  }

  &__order-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
  }

  &__order-confirm-btn {
    background-color: transparent;
    border: 2px solid $gertner-dark-blue;
    color: $gertner-dark-blue;
    font-family: $cool-font-family;
    font-style: italic;
    font-weight: 900;
    font-size: 21px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px 60px;
    text-decoration-line: underline;
  }

  &__order-confirm-btn:hover {
    background-color: $gertner-dark-blue;
    color: #FFFFFF;
  }
}
