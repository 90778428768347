@import "./../../../../variables";

.event-item-container {
  display: flex;
  flex-direction: column;
  font-family: $cool-font-family;
  color: #FFFFFF;
  max-width: 300px;

  &__title {
    font-style: italic;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: flex-end;
    text-decoration-line: underline;
    a {
      text-decoration: none;
      color: #FFFFFF;
    }
  }

  &__date {
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    margin-top: 34px;
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #C4C4C4;
    margin-top: 12px;
  }

  &__venue {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
  }
}
