@import "/../../../variables";

.eshop-header-container {
  width:100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  font-family: $cool-font-family;
  color: $gertner-dark-blue;

  @media only screen and (max-width: $breakpoint-tablet) {
    flex-wrap: wrap;
    max-width: 320px;
  }

  @media only screen and (max-width: $breakpoint-desktop) and (min-width: $breakpoint-tablet){
    justify-content: center;
    align-items: center;
  }

  &__link {
    width: 100%;
  }

  &__logo {
    margin-top: 100px;
    margin-left: 100px;
    @media only screen and (max-width: $breakpoint-tablet) {
      margin-left: 0;
    }

  }

  &__hand {
    width: 612px;
    height: 421px;
    margin-left: 100px;

    &--mobile {
      width: 327px;
      height: 225px;
      margin-top: 30px;
    }
  }

  &__bottom {
    margin-top: 88px;
    display: flex;

    @media only screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @media only screen and (max-width: $breakpoint-desktop) and (min-width: $breakpoint-tablet){
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @media only screen and (max-width: $breakpoint-desktop-large) and (min-width: $breakpoint-desktop){
      margin-left: 100px;
      align-items: center;
      justify-content: center;
    }
  }

  &__description {
    width: 35%;
    @media only screen and (max-width: $breakpoint-tablet) {
      width: 320px;
    }
    @media only screen and (max-width: $breakpoint-desktop) and (min-width: $breakpoint-tablet){
      width: 520px;
    }
  }

  &__title {
    font-style: italic;
    padding-right: 50px;
    font-weight: 900;
    font-size: 48px;
    line-height: 56px;
    @media only screen and (max-width: $breakpoint-desktop) and (min-width: $breakpoint-tablet){
    }
  }

  &__text {
    margin-left: 100px;
    margin-top: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    span {
      font-weight: 900;
      font-style: italic;
      cursor: pointer;
    }
    span:hover {
      text-decoration: underline;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      margin-left: 0;
    }
  }

  &__btn {
    margin-top: 30px;
    margin-left: 100px;
    @media only screen and (max-width: $breakpoint-tablet) {
      margin-left: 0;
    }
  }
}
