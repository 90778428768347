@import "/../../../variables";

.tasting-container {
  max-width: 1440px;
  width: 100%;
  height: 656px;
  background-image: url("./../../../assets/images/degustace.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  font-family: $cool-font-family;

  @media only screen and (max-width: $breakpoint-tablet) {
    width: 375px;
    height: 656px;
    background-image: url("./../../../assets/images/degustace-mobile.png");
  }

  &__content {
    margin-top: 164px;
    margin-left: 150px;
    @media only screen and (max-width: $breakpoint-tablet) {
      margin-top: 300px;
      margin-left: 30px;
    }
  }

  &__title {
    font-style: italic;
    font-weight: 900;
    font-size: 48px;
    line-height: 56px;
    text-align: justify;
    color: #C4C4C4;
  }

  &__text {
    max-width: 300px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    margin-top: 15px;
  }

  &__btn {
    margin-top: 40px;
  }

  &__button {
    font-family: $cool-font-family;
    border: 2px solid #FFFFFF;
    padding: 10px;
    font-weight: 900;
    text-decoration: none;
    color: #FFFFFF;
  }

  &__button:hover {
    text-decoration: underline;
    color: #FFFFFF;
  }
}
