.video-clipp {
    animation: circle 3s;
    clip-path: polygon(25% 0, 0 25%, 25% 50%, 0 75%, 25% 100%, 50% 75%, 75% 100%, 100% 75%, 75% 50%, 100% 25%, 75% 0, 50% 25%);
}

@keyframes circle {
  0% { clip-path: polygon(12% 0, 0% 20%, 6% 48%, 0 72%, 9% 100%, 48% 95%, 89% 100%, 100% 72%, 96% 49%, 100% 20%, 89% 0, 49% 6%); }
  10% { clip-path: polygon(30% 0, 0% 20%, 12% 48%, 0 72%, 20% 100%, 49% 88%, 76% 100%, 100% 72%, 88% 49%, 100% 20%, 72% 0, 49% 14%); }
  100% { clip-path: polygon(25% 0, 0 25%, 25% 50%, 0 75%, 25% 100%, 50% 75%, 75% 100%, 100% 75%, 75% 50%, 100% 25%, 75% 0, 50% 25%); }
}
