@import "./../variables";

.title-page {
  font-size: 30px;

  @media only screen and (max-width: $breakpoint-tablet) {
    font-size: 20px;
  }
}

.error-validation{
  color: #d32533;
  font-size: 16px;
  font-weight: bold;
}

.admin-margin {
  margin-top: 20px;
  margin-bottom: 20px;
}

.admin-modal-center {
  display: flex;
  justify-content: center;
}

.admin-login-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 100px;
}

.admin-bold {
  font-weight: bold;
}
