.user-dashboard-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &__events {
    max-width: 1440px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__element {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
