.order-tile-container {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 50px;
  position:relative;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
  background: rgb(203,203,203);
  background: radial-gradient(circle, rgba(203,203,203,0.8309524493391106) 0%, rgba(200,197,197,1) 100%);
  padding: 25px;
  &__label {
    font-weight: bold;
  }
  &__row{
    margin-top: 15px;
  }
  &__select{
    max-width: 150px;
  }
  &__confirm-btn {
    text-align: right;
  }
}
